import { Component, ChangeEvent } from "react";

import Map from "./map";
import Questions from "./questions";
import Sidebar from "./sidebar";
import Image from "./image";
import Panorama from "./panorama";
import Video from "./video";
import Info from "./info";

import CookieConsent from "./cookieConsent";

import Tour from "reactour";
import { Button } from "@material-ui/core";
import UrlService from "../services/urlService";
import getColourTheme from "../services/colourService";
import { QuestionsStyledProps } from "./questions";

import { isDev } from "../environment";
import { ConsoleLogger } from "@aws-amplify/core";

interface Props {
  firstVisit: boolean;
  host: string;
  mediaStorage: string;
  url: any;
  history: any;
  site: any;
}

interface State {
  site: any;
  longitude: number;
  latitude: number;
  pois: any[];
  bounds: any;
  zoom: number;
  minZoom: number;
  maxZoom: number;
  maxNativeZoomOSM: number;
  maxNativeZoomSatellite: number;
  phases: any[];
  project_description: string;
  isDrawerOpen: boolean;
  isQuestion: boolean;
  description: string;
  image: string;
  currentPhase: any;
  content: JSX.Element;
  isTourOpen: boolean;
  showFocuszones: boolean;
  showQandA: boolean;
  contentQandA: any;
  hasPhases: boolean;
  showCookieConsent: boolean;
  steps: any[];
  projectName: string;
  showQandAButton: boolean;
  isMarkerClicked: boolean;
  styledProps: QuestionsStyledProps;
  tileLayerBounds: any;
  currentQuestionPOI: any;
  youtubeCookie: boolean;
  googleCookie: boolean;
  isFocuszone: boolean;
  walkthroughPhase: number;
  startingPhaseForWalkthrough: any;
  wasCookieOpened: boolean;
}

const urlService = UrlService.getInstance();

const host = isDev();

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let zoom = this.props.site.Location.zoom;
    let latitude;
    let longitude;

    if (this.props.url.zoom !== "") {
      let intZoom = parseInt(this.props.url.zoom);
      if (
        intZoom >= this.props.site.Location.min_zoom &&
        intZoom <= this.props.site.Location.max_zoom
      ) {
        zoom = intZoom;
      }
    }

    if (this.props.url.lat !== "" && this.props.url.lng !== "") {
      latitude = parseFloat(this.props.url.lat);
      longitude = parseFloat(this.props.url.lng);
    } else {
      latitude = this.props.site.Location.latitude;
      longitude = this.props.site.Location.longitude;
    }

    this.state = {
      site: this.props.site,
      longitude: longitude,
      latitude: latitude,
      pois: this.props.site.POI,
      bounds: this.props.site.Bounds,
      zoom: zoom,
      minZoom: this.props.site.Location.min_zoom,
      maxZoom: this.props.site.Location.max_zoom,
      maxNativeZoomOSM: this.props.site.Location.maxNativeZoomOSM,
      maxNativeZoomSatellite: this.props.site.Location.maxNativeZoomSatellite,
      phases: [],
      project_description: this.props.site.project_description,
      isDrawerOpen: false,
      isQuestion: false,
      description: this.props.site.project_description,
      image: "",
      currentPhase: this.props.site.Phase[0],
      content: <div />,
      isTourOpen: false,
      showFocuszones: false,
      showQandA: false,
      contentQandA: null,
      hasPhases: false,
      showCookieConsent: false,
      steps: [],
      projectName: this.props.site.name,
      showQandAButton: false,
      isMarkerClicked: false,
      styledProps: getColourTheme(this.props.site.ColourTheme),
      tileLayerBounds: undefined,
      currentQuestionPOI: undefined,
      youtubeCookie: localStorage.youtubeCookie
        ? localStorage.youtubeCookie === "true"
        : true,
      googleCookie: localStorage.googleCookie
        ? localStorage.googleCookie === "true"
        : true,
      isFocuszone: false,
      startingPhaseForWalkthrough: this.props.site.Phase[0],
      walkthroughPhase: this.props.site.phase_id_walkthrough
        ? this.props.site.phase_id_walkthrough
        : 0,
      wasCookieOpened: false,
    };
  }

  changePhaseForSteps() {
    setTimeout(() => {
      this.setState(
        { currentPhase: this.props.site.Phase[this.state.walkthroughPhase] },
        this.prepareSteps
      );
      if (this.props.url.phase !== "") {
        this.phaseSelected(parseInt(this.props.url.phase));
      }
    }, 500);
  }

  prepareSteps() {
    if (!this.props.site.help_translations) {
      return [];
    }

    const steps = [
      {
        selector: "",
        content: this.props.site.help_translations.Help_welcome,
      },
      {
        selector: "",
        content: this.props.site.help_translations.Help_zoom,
      },
      {
        selector: "",
        content: this.props.site.help_translations.Help_drag,
      },
    ];

    if (document.querySelector(".focuszoneSelector")) {
      steps.push({
        selector: ".focuszoneSelector",
        content: this.props.site.help_translations.Help_focuszones,
      });
    }
    if (document.querySelector(".MuiIconButton-edgeStart")) {
      steps.push({
        selector: ".MuiIconButton-edgeStart",
        content: this.props.site.help_translations.Help_context_menu,
      });
    }
    if (document.querySelector(".appBar-phaseSelector")) {
      steps.push({
        selector: ".appBar-phaseSelector",
        content: this.props.site.help_translations.Help_phases,
      });
    }
    if (document.querySelector(".leaflet-marker-icon")) {
      steps.push({
        selector: ".leaflet-marker-icon",
        content: this.props.site.help_translations.Help_marker,
      });
    }
    this.setState({ steps: steps });
    this.setState({ currentPhase: this.state.phases[0] });
  }

  componentDidMount() {
    this.checkScreen();
    this.orderPhases().then(() => {
      this.setTitle(this.props.site.Title_browser);
      this.props.url.menu === "1"
        ? this.setState({ isDrawerOpen: true })
        : this.setState({ isDrawerOpen: false });
      this.props.url.showFocusZones === "1"
        ? this.setState({ showFocuszones: true })
        : this.setState({ showFocuszones: false });

      let phase: any;
      if (this.props.url.phase !== "") {
        phase = this.state.phases.find(
          (phs: any) => phs.id == this.props.url.phase
        );
      }

      if (this.props.url.qanda !== "" && this.props.url.phase !== "") {
        let focusZone: any;
        if (this.state.phases !== null) {
          focusZone = phase.FocusZones.find(
            (zone: any) => zone.id == this.props.url.qanda
          );
        }
        this.setState({
          contentQandA: parseInt(this.props.url.qanda),
          showQandA: this.props.site.QA_enabled,
          description: focusZone.Description,
        });
      }

      if (this.props.url.focus)
        if (this.props.url.showFocusZones !== "") {
          if (this.props.url.showFocusZones === "1") {
            this.setState({ showFocuszones: true });
          }
        }

      if (this.props.url.focusZoneMenu !== "") {
        if (this.props.url.focusZoneMenu === "1") {
          if (this.props.url.focusZoneMenuId !== "") {
            let currentFocusZone;
            for (let focuszone of this.state.site.Phase[
              this.state.currentPhase.id
            ].FocusZones) {
              if (focuszone.id.toString() === this.props.url.focusZoneMenuId) {
                currentFocusZone = focuszone;
              }
            }
            this.setState({
              isQuestion: false,
              isFocuszone: true,
            });
            this.setState({
              contentQandA: this.props.url.focusZoneMenuId,
              showQandAButton: this.props.site.QA_enabled,
            });
            this.setState({
              description: currentFocusZone.Description,
            });
            this.setState({
              isDrawerOpen: true,
            });
          }
        }
      }

      if (this.props.url.info !== "" && this.props.url.phase !== "") {
        let poi = phase.POIS.find(
          (point: any) => point.id == this.props.url.info
        );
        this.setState({
          isDrawerOpen: true,
          description: poi.description,
        });
      } else if (
        this.props.url.question !== "" &&
        this.props.url.phase !== ""
      ) {
        let poi = phase.POIS.find(
          (point: any) => point.id == this.props.url.question
        );
        this.setState({
          isDrawerOpen: true,
          isQuestion: true,
          description: poi.description,
        });
      } else if (
        this.props.url.phase !== "" &&
        this.props.url.imagePhase !== ""
      ) {
        let poi: any;
        let tempPhase: any = this.state.phases.find(
          (phs: any) => phs.id == this.props.url.imagePhase
        );

        if (this.props.url.image !== "") {
          poi = tempPhase.POIS.find(
            (point: any) => point.id == this.props.url.image
          );
        } else if (this.props.url.video !== "") {
          poi = tempPhase.POIS.find(
            (point: any) => point.id == this.props.url.video
          );
        } else if (this.props.url.pano !== "") {
          poi = tempPhase.POIS.find(
            (point: any) => point.id == this.props.url.pano
          );
        }
        if (poi != undefined) {
          let hasPhases = this.checkIfImageHasPhases(poi);
          let imagePhase;
          if (this.props.url.imagePhase !== "") {
            imagePhase = this.state.phases.find(
              (phs: any) => phs.id == this.props.url.imagePhase
            );
          } else {
            imagePhase = this.state.currentPhase;
          }
          if (this.props.url.image !== "") {
            this.setState({
              content: (
                <Image
                  host={this.props.mediaStorage}
                  url={poi.img[0].url}
                  deleteContent={this.deleteContent}
                  poi={poi}
                  hasPhases={hasPhases}
                  phases={this.state.phases}
                  currentPhase={imagePhase}
                  history={this.props.history}
                  translations={this.state.site.help_translations}
                  projectName={this.state.projectName}
                  sidebarWidth={this.state.site.sidebar_width}
                  isDrawerOpen={this.state.isDrawerOpen}
                  showFocuszones={this.state.showFocuszones}
                  toggleDrawer={this.toggleDrawer}
                  closeDrawer={this.closeDrawer}
                  phaseSelected={this.phaseSelected}
                  googleCookie={this.state.googleCookie}
                  questionsStyledProps={this.state.styledProps}
                />
              ),
              description: poi.description,
            });
          } else if (this.props.url.video !== "") {
            this.setState({
              content: (
                <Video
                  url={poi.url}
                  deleteContent={this.deleteContent}
                  phases={this.state.phases}
                  poi={poi}
                  currentPhase={imagePhase}
                  hasPhases={hasPhases}
                  history={this.props.history}
                  translations={this.state.site.help_translations}
                  projectName={this.state.projectName}
                  sidebarWidth={this.state.site.sidebar_width}
                  showFocuszones={this.state.showFocuszones}
                  phaseSelected={this.phaseSelected}
                  isDrawerOpen={this.state.isDrawerOpen}
                  toggleDrawer={this.toggleDrawer}
                  closeDrawer={this.closeDrawer}
                  googleCookie={this.state.googleCookie}
                  youtubeCookie={this.state.youtubeCookie}
                  questionsStyledProps={this.state.styledProps}
                  openCookie={this.openCookie}
                />
              ),
              description: poi.description,
            });
          } else if (this.props.url.pano !== "") {
            this.setState({
              content: (
                <Panorama
                  host={this.props.mediaStorage}
                  url={poi.img[0].url}
                  deleteContent={this.deleteContent}
                  phases={this.state.phases}
                  poi={poi}
                  currentPhase={imagePhase}
                  hasPhases={hasPhases}
                  history={this.props.history}
                  translations={this.state.site.help_translations}
                  projectName={this.state.projectName}
                  sidebarWidth={this.state.site.sidebar_width}
                  phaseSelected={this.phaseSelected}
                  isDrawerOpen={this.state.isDrawerOpen}
                  toggleDrawer={this.toggleDrawer}
                  closeDrawer={this.closeDrawer}
                  googleCookie={this.state.googleCookie}
                  questionsStyledProps={this.state.styledProps}
                />
              ),
              description: poi.description,
            });
          }
        }
      }
      localStorage.getItem("cookieConsent")
        ? this.setState({ showCookieConsent: false })
        : this.setState({ showCookieConsent: true });
      if (this.props.url.phase === "" && this.state.phases) {
        this.checkForBounds(this.state.currentPhase);
      }
    });
    this.changePhaseForSteps();
  }

  orderPhases = async () => {
    const response = await fetch(host + "/sites/" + this.state.site.id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const body = await response.json();
    let phaseArray = [];
    let i = 0;
    for (let phase of body.Phase) {
      phase.id = i;
      i++;
      phaseArray.push(phase);
    }
    this.setState({ phases: phaseArray });
    if (this.props.url.phase === "") {
      this.setState({ currentPhase: phaseArray[0] });
    }
    if (this.props.url.phase !== "") {
      this.phaseSelected(parseInt(this.props.url.phase));
    }
  };

  checkScreen = () => {
    if (window.innerWidth < 600) {
      const site = this.state.site;
      site.sidebar_width = 250;
      this.setState({ site: site });
    }
  };

  checkTour = () => {
    this.setState({ isTourOpen: this.props.firstVisit });
  };

  phaseSelected = (phaseId: number) => {
    this.state.phases.forEach((phase: any, index: number) => {
      if (phase.id === phaseId) {
        this.setState({ currentPhase: phase });
        this.props.history.push(urlService.setPhaseParam(phaseId.toString()));
        this.checkForBounds(phase);
      }
    });
  };

  toggleDrawer = () => {
    this.state.isDrawerOpen
      ? this.props.history.push(urlService.setMenuParam("0"))
      : this.props.history.push(urlService.setMenuParam("1"));
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };

  toggleFocuszones = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.showFocuszones
      ? this.props.history.push(urlService.setShowFocusZoneParam("0"))
      : this.props.history.push(urlService.setShowFocusZoneParam("1"));
    this.setState({ showFocuszones: !this.state.showFocuszones });
  };

  closeDrawer = () => {
    if (this.state.isDrawerOpen === true) {
      this.setState({ isFocuszone: false });
      this.setState({ isDrawerOpen: false });
      this.props.history.push(urlService.setFocuszoneMenu("0", "0"));
      this.props.history.push(urlService.setMenuParam("0"));
      this.props.history.push(urlService.removeInfoParam());
      this.props.history.push(urlService.removeQuestionParam());
      this.setState({ contentQandA: null });
      this.setState({ currentQuestionPOI: null });
      setTimeout(() => {
        if (this.state.isDrawerOpen === false) {
          this.setState({
            description: this.state.project_description,
            image: "",
            showQandAButton: false,
            isQuestion: false,
          });
        }
      }, 1000);
    }
  };

  goBack = () => {
    this.setState({
      description: this.state.project_description,
      isQuestion: false,
      showQandAButton: false,
    });
  };

  setCurrentActiveFocuszoneData = (description: string, image: string) => {
    this.setState({
      isQuestion: false,
      description: description,
      image: this.props.host + image,
    });
  };

  setCurrentActiveFocuszoneQandA = (
    questionsId: any,
    description?: any,
    image?: any
  ) => {
    this.props.history.push(urlService.setFocuszoneMenu("1", questionsId));
    if (!this.state.isFocuszone) this.deleteContent();
    this.setState({ isQuestion: false, isFocuszone: true });
    if (this.state.contentQandA === questionsId && this.props.site.QA_enabled) {
      this.props.history.push(urlService.setFocuszoneMenu("0", questionsId));
      this.setState({ showQandA: true });
      this.props.history.push(urlService.setQandaParam(questionsId));
      this.props.history.push(
        urlService.setPhaseParam(this.state.currentPhase.id.toString())
      );
    } else {
      this.setState({
        contentQandA: questionsId,
        showQandAButton: this.props.site.QA_enabled,
      });
      this.setState({
        description: description,
        image: this.props.host + image,
      });
      this.setState({
        isDrawerOpen: true,
      });
    }
  };

  handleOpenQandAButton = () => {
    this.setState({ showQandA: this.props.site.QA_enabled });
  };

  isQAshown = () => {
    return this.state.showQandA && this.props.site.QA_enabled;
  };

  closeQandA = () => {
    this.setState({
      showQandA: false,
      contentQandA: null,
      isDrawerOpen: false,
      showQandAButton: false,
      description: this.state.site.project_description,
      image: "",
      isFocuszone: false,
    });
    this.props.history.push(urlService.removeQandaParam());
  };

  markerClicked = (poi: any) => {
    this.deleteContent();
    let hasPhases = this.checkIfImageHasPhases(poi);
    this.props.history.push(
      urlService.setPhaseParam(this.state.currentPhase.id.toString())
    );
    switch (poi.Marker) {
      case "url":
        window.open(poi.url);
        break;
      case "info":
        this.setState({
          content: (
            <Info
              poi={poi}
              currentPhase={this.state.currentPhase}
              description={poi.description}
              phases={this.state.phases}
              isDrawerOpen={this.state.isDrawerOpen}
              hasPhases={this.state.hasPhases}
              translations={this.state.site.help_translations}
              projectName={this.state.projectName}
              sidebarWidth={this.state.site.sidebar_width}
              googleCookie={this.state.googleCookie}
              history={this.props.history}
              questionsStyledProps={this.state.styledProps}
              showFocuszones={this.state.showFocuszones}
              toggleDrawer={this.toggleDrawer}
              closeDrawer={this.closeDrawer}
              toggleFocuszones={this.toggleFocuszones}
              deleteContent={this.deleteContent}
              goBack={this.goBack}
              phaseSelected={(phaseId) => this.phaseSelected(phaseId)}
              openDrawer={this.openDrawer}
            />
          ),
          isMarkerClicked: true,
          isFocuszone: false,
          image: "",
          description: poi.description,
          isQuestion: false,
        });
        this.props.history.push(urlService.setInfoParam(poi.id));
        break;
      case "question":
        this.deleteContent();
        this.setState({
          isDrawerOpen: true,
          isQuestion: true,
          description: poi.description,
          image: "",
          currentQuestionPOI: poi,
        });
        this.props.history.push(urlService.setQuestionParam(poi.id));
        break;
      case "image":
        this.props.history.push(urlService.setImageParam(poi.id));
        this.props.history.push(
          urlService.setImagePhaseParam(this.state.currentPhase.id.toString())
        );
        this.setState({
          content: (
            <Image
              host={this.props.mediaStorage}
              url={poi.img[0].url}
              deleteContent={this.deleteContent}
              poi={poi}
              hasPhases={hasPhases}
              phases={this.state.phases}
              currentPhase={this.state.currentPhase}
              history={this.props.history}
              translations={this.state.site.help_translations}
              projectName={this.state.projectName}
              sidebarWidth={this.state.site.sidebar_width}
              isDrawerOpen={this.state.isDrawerOpen}
              showFocuszones={this.state.showFocuszones}
              toggleDrawer={this.toggleDrawer}
              closeDrawer={this.closeDrawer}
              phaseSelected={this.phaseSelected}
              googleCookie={this.state.googleCookie}
              questionsStyledProps={this.state.styledProps}
            />
          ),
          isMarkerClicked: true,
        });
        break;
      case "image360":
        this.props.history.push(urlService.setPanoramaParam(poi.id));
        this.props.history.push(
          urlService.setImagePhaseParam(this.state.currentPhase.id.toString())
        );
        this.setState({
          content: (
            <Panorama
              host={this.props.mediaStorage}
              url={poi.img[0].url}
              deleteContent={this.deleteContent}
              phases={this.state.phases}
              poi={poi}
              currentPhase={this.state.currentPhase}
              hasPhases={hasPhases}
              history={this.props.history}
              translations={this.state.site.help_translations}
              projectName={this.state.projectName}
              sidebarWidth={this.state.site.sidebar_width}
              phaseSelected={this.phaseSelected}
              isDrawerOpen={this.state.isDrawerOpen}
              toggleDrawer={this.toggleDrawer}
              closeDrawer={this.closeDrawer}
              googleCookie={this.state.googleCookie}
              questionsStyledProps={this.state.styledProps}
            />
          ),
          isMarkerClicked: true,
        });
        break;
      case "video":
        this.props.history.push(urlService.setVideoParam(poi.id));
        this.props.history.push(
          urlService.setImagePhaseParam(this.state.currentPhase.id.toString())
        );
        this.setState({
          content: (
            <Video
              url={poi.url}
              deleteContent={this.deleteContent}
              phases={this.state.phases}
              poi={poi}
              currentPhase={this.state.currentPhase}
              hasPhases={hasPhases}
              history={this.props.history}
              translations={this.state.site.help_translations}
              projectName={this.state.projectName}
              sidebarWidth={this.state.site.sidebar_width}
              showFocuszones={this.state.showFocuszones}
              phaseSelected={this.phaseSelected}
              isDrawerOpen={this.state.isDrawerOpen}
              toggleDrawer={this.toggleDrawer}
              closeDrawer={this.closeDrawer}
              googleCookie={this.state.googleCookie}
              youtubeCookie={this.state.youtubeCookie}
              questionsStyledProps={this.state.styledProps}
              openCookie={this.openCookie}
            />
          ),
          isMarkerClicked: true,
        });
        break;
    }
  };

  deleteContent = () => {
    if (this.state.isDrawerOpen) this.findAppBar();
    this.setState({ isFocuszone: false });
    this.setState({ content: <div /> });
    this.setState({ isMarkerClicked: false });
    this.setState({ isQuestion: false });
    let zoomControls = document.querySelector<HTMLElement>(
      ".leaflet-control-zoom"
    );
    zoomControls?.classList.remove("zoom-control-custom-animation-open");
    zoomControls?.removeAttribute("style");
    if (this.state.hasPhases === true) this.setState({ hasPhases: false });
    this.clearMarkerParams();
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
    this.setState({ isDrawerOpen: false });
  };

  checkIfImageHasPhases = (poi: any) => {
    if (poi.other_phases !== "" && poi.other_phases !== null) {
      this.setState({ hasPhases: true });
      return true;
    }
    return false;
  };

  checkForBounds = (phase: any) => {
    if (phase && phase.tiles_bounds) {
      let tempArray = [];
      for (let coord of phase.tiles_bounds) {
        const coords = [coord[1], coord[0]];
        tempArray.push(coords);
      }
      this.setState({ tileLayerBounds: tempArray });
    } else {
      this.setState({ tileLayerBounds: undefined });
    }
  };

  handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "youtube") {
      this.setState({ youtubeCookie: !this.state.youtubeCookie });
    }
    if (event.target.name === "google") {
      this.setState({ googleCookie: !this.state.googleCookie });
    }
  };

  handleButtonCookie = () => {
    localStorage.youtubeCookie = this.state.youtubeCookie;
    localStorage.googleCookie = this.state.googleCookie;
    localStorage.setItem("cookieConsent", "true");
    this.setState({ showCookieConsent: false });
    if (!this.state.wasCookieOpened) {
      this.checkTour();
    }
    this.setState({ wasCookieOpened: true });
  };

  openCookie = () => {
    this.setState({ showCookieConsent: true });
  };

  setTitle(title: string) {
    document.title = title;
  }

  clearMarkerParams() {
    this.props.history.push(urlService.removeImageParam());
    this.props.history.push(urlService.removeImagePhaseParam());
    this.props.history.push(urlService.removeInfoParam());
    this.props.history.push(urlService.removePanoramaParam());
    this.props.history.push(urlService.removeQuestionParam());
    this.props.history.push(urlService.removeVideoParam());
    this.goBack();
  }

  openDrawer = () => {
    this.setState({ isDrawerOpen: true });
  };

  findAppBar = () => {
    setTimeout(() => {
      const appBar = document.querySelectorAll(".appBar-animation");
      appBar[0]?.classList.remove("appBar-animation");
      this.addAnimation();
    }, 10);
  };

  addAnimation = () => {
    setTimeout(() => {
      const appBar = document.querySelectorAll(".appBar");
      appBar[0]?.classList.add("appBar-animation");
    }, 10);
  };

  render() {
    return (
      <div>
        <div
          style={{
            zIndex: 2000,
            width: "10px",
            height: "100%",
            position: "fixed",
            overflowY: "hidden",
            backgroundColor: this.state.styledProps.mainAccent,
          }}
        ></div>
        {this.state.showCookieConsent && (
          <CookieConsent
            styledProps={this.state.styledProps}
            handleSwitchChange={(event) => {
              this.handleSwitchChange(event);
            }}
            handleButtonCookie={this.handleButtonCookie}
            youtubeCookie={this.state.youtubeCookie}
            googleCookie={this.state.googleCookie}
          />
        )}
        {this.isQAshown() && (
          <Questions
            closeQandA={this.closeQandA}
            phases={this.state.phases}
            currentFocuszoneContentQandA={this.state.contentQandA}
            description={this.state.description}
            image={this.state.image}
            questionsStyledProps={this.state.styledProps}
            project_name={this.state.projectName}
            sidebarWidth={this.state.site.sidebar_width}
            googleCookie={this.state.googleCookie}
            translations={this.state.site.help_translations}
            openCookie={this.openCookie}
          />
        )}
        <Tour
          steps={this.state.steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.closeTour()}
          closeWithMask={false}
          disableInteraction={true}
          lastStepNextButton={
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: this.state.styledProps.mainAccent,
                color: this.state.styledProps.mainAccentFontColor,
              }}
            >
              {this.state.site.help_translations.Help_done}
            </Button>
          }
          accentColor={this.state.styledProps.mainAccent}
          startAt={0}
          onAfterOpen={(target) => {
            this.setState({
              startingPhaseForWalkthrough: this.state.currentPhase,
            });
            this.setState({
              currentPhase: this.state.phases[this.state.walkthroughPhase],
            });
          }}
          onBeforeClose={(target) =>
            this.setState({
              currentPhase: this.state.startingPhaseForWalkthrough,
            })
          }
        />
        {this.state.content}
        {!this.state.hasPhases && !this.state.isMarkerClicked && (
          <Sidebar
            key={this.state.site.sidebar_width}
            questionsStyledProps={this.state.styledProps}
            isDrawerOpen={this.state.isDrawerOpen}
            isQuestion={this.state.isQuestion}
            closeDrawer={this.closeDrawer}
            toggleDrawer={this.toggleDrawer}
            description={this.state.description}
            image={this.state.image}
            openTour={this.openTour}
            phases={this.state.phases}
            phaseSelected={this.phaseSelected}
            showFocuszones={this.state.showFocuszones}
            showFocuszonesCallback={this.toggleFocuszones}
            currentPhase={this.state.currentPhase}
            hasPhases={this.state.hasPhases}
            isMediaShown={false}
            translations={this.state.site.help_translations}
            projectName={this.state.projectName}
            showQandAButton={this.state.showQandAButton}
            handleQandAButton={this.handleOpenQandAButton}
            sidebarWidth={this.state.site.sidebar_width}
            isMarkerClicked={this.state.isMarkerClicked}
            goBack={
              this.state.project_description === this.state.description
                ? undefined
                : this.goBack
            }
            currentQuestionPOI={this.state.currentQuestionPOI}
            googleCookie={this.state.googleCookie}
            openCookie={this.openCookie}
          />
        )}
        {this.state.latitude !== null && this.state.longitude !== null && (
          <Map
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            zoom={this.state.zoom}
            minZoom={this.state.minZoom}
            maxZoom={this.state.maxZoom}
            maxNativeZoomOSM={this.state.maxNativeZoomOSM}
            maxNativeZoomSatellite={this.state.maxNativeZoomSatellite}
            phases={this.state.phases}
            bounds={this.state.bounds}
            project_description={this.state.project_description}
            currentPhase={this.state.currentPhase}
            markerClicked={(poi) => this.markerClicked(poi)}
            host={this.props.host}
            mediaStorage={this.props.mediaStorage}
            showFocuszones={this.state.showFocuszones}
            changeCurrentActiveFocuszoneData={
              this.setCurrentActiveFocuszoneData
            }
            setCurrentActiveFocuszoneQandA={this.setCurrentActiveFocuszoneQandA}
            isSatteliteButtonVisible={this.state.site.sattelite_button}
            isSearchButtonVisible={this.state.site.search_button}
            isMapBlank={this.state.site.blank_map}
            projectName={this.state.site.name}
            tileLayerBounds={this.state.tileLayerBounds}
            questionsStyledProps={this.state.styledProps}
            translations={this.props.site.help_translations}
          />
        )}
      </div>
    );
  }
}

export default Home;
