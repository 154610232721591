import { useState, useEffect, ChangeEvent } from 'react';

import Sidebar from './sidebar';

import UrlService from '../services/urlService';

interface Props {
  poi: any;
  currentPhase: any;
  description: string;
  phases: any;
  isDrawerOpen: boolean;
  hasPhases: boolean;
  translations: any;
  projectName: string;
  sidebarWidth: number;
  googleCookie: boolean;
  history: any;
  questionsStyledProps: any;
  showFocuszones: boolean;
  toggleDrawer: () => void;
  closeDrawer: () => void;
  deleteContent: () => void;
  goBack: () => void;
  openDrawer: () => void;
  toggleFocuszones: (event: ChangeEvent<HTMLInputElement>) => void;
  phaseSelected: (phaseId: any) => void;
}

const Info = (props: Props) => {
  const [poi, setPoi] = useState(props.poi);
  const [currentPhase, setCurrentPhase] = useState(props.currentPhase);
  const [description, setDescription] = useState(props.description);
  const [isDrawerOpen, setIsDrawerOpen] = useState(props.isDrawerOpen);
  const [phases, setPhases] = useState(props.phases);
  const [showFocuszones, setShowFocuszones] = useState(props.showFocuszones);

  const urlService = UrlService.getInstance();

  useEffect(() => {
    setIsDrawerOpen(true);
    props.openDrawer();
  }, []);

  useEffect(() => {
    setPhases([]);
    for (let phase of props.phases) {
      for (let point of phase.POIS) {
        if (poi.other_phases) {
          const otherPhasesArray: Array<String> = poi.other_phases.split(',');
          for (let otherPhase of otherPhasesArray) {
            if (point.poi_id === otherPhase) {
              let phaseArray = [currentPhase];
              phaseArray.push(phase);
              setPhases(phaseArray);
            }
          }
        }
      }
    }

    setDescription(poi.description);
  }, [poi]);

  const onPhaseSelected = (phaseId: number) => {
    if (poi !== null || poi !== undefined) {
      const otherPhasesArray = poi.other_phases.split(',');
      for (let phase of props.phases) {
        if (phaseId === phase.id) {
          for (let point of phase.POIS) {
            for (let otherPhase of otherPhasesArray) {
              if (point.poi_id === otherPhase) {
                setPoi(point);
                setCurrentPhase(phase);
                setDescription(point.description);
                props.history.push(urlService.setInfoParam(point.id));
              }
            }
          }
        }
      }
      props.phaseSelected(phaseId);
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    props.toggleDrawer();
  };

  const closeDrawer = () => {
    if (isDrawerOpen === true) {
      setIsDrawerOpen(false);

      props.closeDrawer();
      setTimeout(() => {
        props.deleteContent();
      }, 200);
    }
  };

  const goBack = () => {
    props.goBack();
    props.deleteContent();
    props.openDrawer();
  };

  const toggleFocuszones = (event: ChangeEvent<HTMLInputElement>) => {
    setShowFocuszones(!showFocuszones);
    props.toggleFocuszones(event);
  };

  return (
    <Sidebar
      isDrawerOpen={isDrawerOpen}
      isQuestion={false}
      closeDrawer={closeDrawer}
      toggleDrawer={toggleDrawer}
      description={description}
      image={''}
      phases={phases}
      phaseSelected={onPhaseSelected}
      showFocuszones={showFocuszones}
      showFocuszonesCallback={toggleFocuszones}
      currentPhase={currentPhase}
      hasPhases={props.hasPhases}
      isMediaShown={true}
      translations={props.translations}
      projectName={props.projectName}
      sidebarWidth={props.sidebarWidth}
      googleCookie={props.googleCookie}
      questionsStyledProps={props.questionsStyledProps}
      goBack={goBack}
    />
  );
};

export default Info;
