export default function TermsOfUse() {
  return (
    <div className="termsOfUse">
      <h1>LEGAL NOTICES</h1>
      <div className="termsOfUse-content">
        <h2>Website editor</h2>
        <p>TRACTEBEL ENGINEERING S.A. / N.V. (TRACTEBEL)</p>
        <p>Address: Boulevard Simon Bolivar 34-36, 1000 Brussels, Belgium</p>
        <p>Phone number:</p>
        <p>RCS Number: 0412 639 681</p>
        <p>Email: support.uwm@tractebel.engie.com</p>
      </div>
      <div className="termsOfUse-content">
        <h2>Publication Manager</h2>
        <p>Group: Tractebel Engineering N.V. Hasselt Office / Urban</p>
        <p>Address: Ilgatlaan 23, 3500 – Hasselt Belgium</p>
        <p>Phone number: +32 11 28 86 00</p>
        <p>Email: support.uwm@tractebel.engie.com</p>
      </div>
      <div className="termsOfUse-content">
        <h2>Hosting</h2>
        <p>Our services are hosted on AWS (Amazon Web Services) located in the European Union Region. AWS is a well-known and trusted data center that meets the requirements of security-sensitive organizations while providing data privacy.</p>
      </div>
      <div className="termsOfUse-content termsOfUse-legal">
        <h2>Terms of use</h2>
        <p>
        Welcome to the application of the UrbanWebMap (hereinafter the “Application”), project lead by TRACTEBEL.
        </p>
        <p>
        Please read these terms of use (hereinafter the “Terms”), and any special terms stated on the pages of the Application, which govern your use of the “UrbanWebMap” Application. These Terms cover your rights and legal responsibilities, and become applicable as soon as you start to use this Application.
        </p>
        <p>
          This Application is made available to you free of charge (except for
          Internet connection costs) for your personal use, subject to
          compliance with the Terms set out below.
        </p>
        <p>
          By accessing, visiting and/or using this Application, you acknowledge
          that you have read, understood and agree to be bound by these Terms,
          and that you undertake to comply with all relevant laws and
          regulations. If you do not agree to these Terms, please do not use
          this Application.
        </p>
        <p>
          You undertake to access the information provided on this Application
          exclusively for personal and non-commercial use.
        </p>
        <p>
          You may only use the Application for legitimate purposes. It may not
          be used or misappropriated for purposes contrary to public order and
          morality.
        </p>
        <p>
          The content of these Terms of use may change. We therefore advise you
          to read them regularly.
        </p>
        <p>
        This Application is the property of TRACTEBEL, a company organised and existing under the laws of Belgium, having its registered office at Boulevard Simon Bolivar 34-36, 1000 Brussels, Belgium, registered with the Crossroad Bank of Enterprises under number RPM Brussels BE 0412 639 681.
        </p>
        <h3>Limitation of liability and guarantees</h3>
        <p>
          As an user of the Application, you acknowledge that you have the
          necessary skills and means to access and use this Application.
        </p>
        <p>
        TRACTEBEL and its contributors make every effort to ensure that the information provided on this Application is accurate and up-to-date, reserving the right to modify the content at any time and without notice. However, they cannot guarantee that this information is complete, or that it has not been modified by a third party (hacking, virus, etc.). TRACTEBEL and its contributors also accept no liability (direct or indirect) in the event of delay, error or omission with regard to the contents and use of these pages and in the event of the service being interrupted or unavailable.
        </p>
        <p>
        You agree that you have been informed that the Application is accessible 24 hours a day, 7 days a week, except in the event of force majeure, server problems, problems caused by the telecommunications network infrastructures or other technical difficulties. TRACTEBEL may occasionally shut down its Application for maintenance purposes. In this case, it will make every effort to notify users in advance.
        </p>
        <p>
        TRACTEBEL is not responsible for delays, operating problems or incompatibility between the Application and files, your browser or any other software used for access to the Application.
        </p>
        <p>
        TRACTEBEL may under no circumstances be held liable for any direct or indirect damage resulting from or subsequent to third-party distribution of a virus by means of our Application, which may infect your computer system following connection to this Application, use of this Application or browsing on this Application. Likewise, TRACTEBEL may not be held liable for any material or consequential damage (including but not limited to technical failure, disclosure of confidential documents and data loss), or for any other indirect damage, arising from or associated with the use of the Application.
        </p>
        <p>
        TRACTEBEL makes every possible effort to ensure that the information provided on the Application is accurate and up-to-date, and reserves the right to correct the content at any time and without prior notice. However, TRACTEBEL and its contributors make no undertaking and assume no liability, under any circumstances whatsoever, with regard to the relevance, continuity, accuracy, absence of errors, veracity, timeliness, fair and commercial nature, quality, validity and availability of the information contained in this Application. Each Internet user shall be fully responsible for the risks incurred by giving credence to this information. Incorrect information or omissions may be encountered arising from typographical or formatting errors. If you find any such errors, please inform us so that we can make the relevant corrections.
        </p>
        <p>
          The information on the Application is provided “as seen”, without
          guarantee of any kind, whether implied or explicit.
        </p>
        <p>
          TRACTEBEL reserves the right to modify any content of the Application
          at its sole discretion.
        </p>
        <p>
        Pursuant to its policy of updating and improving the Application, TRACTEBEL may decide to modify these Terms. Any dated information published on the Application is valid exclusively for the specified date.
        </p>
        <p>
        Users are also reminded that the confidentiality of correspondence cannot be guaranteed on the network and that every Internet user is responsible for taking all appropriate measures to protect their own data and/or software from contamination by any viruses that may be circulating on the Internet.
        </p>
        <p>
        The Application includes links to other TRACTEBEL websites or any other companies likely to have their own legal conditions, which should be read and complied with.
        </p>
        <h3>Intellectual property</h3>
        <p>
          The Application is fully subject to Belgium law on copyright,
          trademarks and, in general, on intellectual property.
        </p>
        <p>
          TRACTEBEL’s trademarks and logos (semi-figurative trademarks) shown on
          the are registered trademarks.
        </p>
        <p>
          Any full or partial reproduction or representation, alone or in
          company with other elements, without the prior express written
          authorisation of TRACTEBEL is strictly forbidden.
        </p>
        <p>
        The general structure, software, texts, images, videos, sounds, know-how, animations, and more generally all information and content shown on the Application, are the property of TRACTEBEL or are subject to rights of use or exploitation. These elements are subject to copyright law.
        </p>
        <p>
        Any full or partial representation, modification, reproduction, distortion, of all or part of the Application or its contents, by any procedure whatsoever and in any medium whatsoever, constitutes an infringement sanctioned by articles L 335-2 of Intellectual Property Code.
        </p>
        <p>
        These Terms do not grant you any licence for the use of trademarks, logos or photographs belonging to TRACTEBEL. Any databases appearing on the Application are protected by the legal provisions for the protection of databases. In this respect, TRACTEBEL expressly prohibits any re-use, reproduction or extraction of elements of these databases. The user shall be held liable for any such re-use, reproduction or extraction.
        </p>
        <p>
        TRACTEBEL reserves the right to delete immediately and without prior notice any content – message, text, image or graphic that contravenes the laws and regulations in force and particularly the regulations specified above.
        </p>
        <p>
        If you wish to use some of the Application content (text, image, etc.), you must obtain prior express written authorisation from TRACTEBEL, by writing to the address given in the paragraph “Legal information” or by sending an e-mail to the webmaster at the following address : support.uwm@tractebel.engie.com.
        </p>
        <h3>Hyperlinks</h3>
        <p>
          TRACTEBEL may not be held liable for hyperlinks to other websites, and
          particularly for the content of those websites.
        </p>
        <p>
          TRACTEBEL is not liable for hyperlinks from other sites to this
          Application and no such links may be set up without its prior written
          authorisation.
        </p>
        <h3>Applicable law and jurisdiction</h3>
        <p>
          Any assignment or other transfer of the rights granted under these
          conditions is strictly forbidden.
        </p>
        <p>
          If, for any reason whatsoever, a competent court were to rule that a
          provision of these Terms is invalid, the invalidity of that provision
          will not in any way affect the validity of the remaining Terms, which
          will remain in force. Failure by TRACTEBEL or the user (hereinafter
          "the Parties") to exercise a right or to institute legal proceedings
          under the terms of these Terms shall not be deemed to be a waiver of
          such a right or such proceedings.</p>
          <p> The Application is subject to
          Belgium law. These Terms will be governed by and interpreted in
          accordance with Belgium law. In the event of a dispute, the Parties
          must, prior to any referral, find an amicable solution. In the absence
          of an amicable solution, the competent courts to hear the dispute
          shall be the Belgium courts with material and territorial
          jurisdiction.
        </p>
      </div>
    </div>
  );
}
