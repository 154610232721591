import { FC } from 'react';

import { LatLngExpression } from 'leaflet';
import { useMap } from 'react-leaflet';

type Props = {
  center: LatLngExpression;
  zoom: number;
};

const SetView: FC<Props> = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);

  return null;
};

export default SetView;
