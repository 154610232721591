import React, { Component } from 'react';

import {
  IconButton,
  InputBase,
  Paper,
  Theme,
  withStyles,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Styles } from '@material-ui/core/styles/withStyles';

interface Props {
  bounds: any;
  changeCenter: (longitude: number, latitude: number) => void;
  classes?: any;
}

interface State {
  searchQuery: string;
  bounds: number[];
  isExpanded: boolean;
  found: string[];
  lat: number[];
  lon: number[];
  isAddressFound: boolean;
}

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'space-between',
    transition: 'width 500ms',
    width: '45px',
  },
  expanded: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center !important',
    transition: 'width 500ms',
    width: '250px',
  },
  searchInput: {
    transition: 'margin 500ms',
    marginLeft: '8px',
  },
  addressList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center !important',
    width: '200px',
    marginRight: '50px',
  },
});

class SearchBar extends Component<Props, State> {
  private wrapperRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      searchQuery: '',
      bounds: [],
      isExpanded: false,
      found: [],
      lat: [],
      lon: [],
      isAddressFound: false,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  async getLocation(query: string) {
    const url = this.props.bounds
      ? 'https://nominatim.openstreetmap.org/search.php?q=' +
        query +
        '&viewbox=' +
        this.props.bounds.NELongitude +
        ',' +
        this.props.bounds.NELatitude +
        ',' +
        this.props.bounds.SWLongitude +
        ',' +
        this.props.bounds.SWLatitude +
        '&bounded=1&format=jsonv2'
      : 'https://nominatim.openstreetmap.org/search.php?q=' +
        query +
        '&bounded=1&format=jsonv2';
    const response = await fetch(url, {
      method: 'GET',
    });
    const body = await response.json();
    if (body.length === 0) {
      const tempArray = ['No address found'];
      this.setState({ found: tempArray });
      this.setState({ isAddressFound: false });
    } else if (body.length === 1) {
      this.props.changeCenter(body[0].lat, body[0].lon);
      this.setState({ isAddressFound: true });
    } else if (body.length > 1) {
      let tempArray = [];
      let tempLat = [];
      let tempLon = [];
      for (let i = 0; i < body.length; i++) {
        tempArray.push(body[i].display_name);
        tempLat.push(body[i].lat);
        tempLon.push(body[i].lon);
      }
      this.setState({ found: tempArray });
      this.setState({ lat: tempLat });
      this.setState({ lon: tempLon });
      this.setState({ isAddressFound: true });
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({ searchQuery: event.target.value });
  };

  handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmit();
    }
  };

  handleClick = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
    if (this.state.isExpanded) {
      this.setState({ found: [] });
    }
  };

  handleSubmit = () => {
    this.getLocation(this.state.searchQuery);
  };

  handleAddressClick = (address: string, index: number) => {
    this.setState({ searchQuery: address });
    this.setState({ found: [] });
    this.props.changeCenter(this.state.lat[index], this.state.lon[index]);
  };

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target as Node)
    ) {
      this.setState({ isExpanded: false });
      this.setState({ found: [] });
      this.setState({ searchQuery: '' });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="searchBar" ref={this.wrapperRef}>
        <Paper
          component="div"
          className={this.state.isExpanded ? classes.expanded : classes.root}
        >
          <form onKeyPress={this.handleKeyPress}>
            <InputBase
              className={this.state.isExpanded ? classes.searchInput : null}
              placeholder="Search"
              value={this.state.searchQuery}
              onChange={this.handleChange}
            />
          </form>
          <IconButton
            onClick={
              this.state.isExpanded ? this.handleSubmit : this.handleClick
            }
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        {this.state.found.length > 0 && this.state.isExpanded && (
          <Paper component="span" className={classes.addressList}>
            {this.state.isAddressFound &&
              this.state.found.map((address: string, index: number) => (
                <Button
                  onClick={() => {
                    this.handleAddressClick(address, index);
                  }}
                  key={index}
                >
                  <span className="searchBar--address">{address}</span>
                </Button>
              ))}
            {!this.state.isAddressFound && (
              <Button>{this.state.found[0]}</Button>
            )}
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(useStyles)(SearchBar);
