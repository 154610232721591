import React, { Component } from "react";

import { renderToStaticMarkup } from "react-dom/server";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  ZoomControl,
  AttributionControl,
} from "react-leaflet";
import L, {
  latLng,
  LatLngBoundsExpression,
  LatLngExpression,
  divIcon,
} from "leaflet";

import { ReactComponent as PanoramicIcon } from "../icons/vrpano_black_24dp.svg";

import InfoIcon from "@material-ui/icons/Info";
import QuestionIcon from "@material-ui/icons/Help";
import ImageIcon from "@material-ui/icons/Image";
import VideoIcon from "@material-ui/icons/YouTube";
import PanoramaIcon from "@material-ui/icons/Panorama";
import { ReactComponent as LinkIcon } from "../icons/linkicon.svg";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { MapModeToggler } from "./togglers";

import SatelliteIcon from "@material-ui/icons/Satellite";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import { Theme, IconButton } from "@material-ui/core";

import { BasemapLayer } from "react-esri-leaflet";

import SearchBar from "./searchBar";
import SetView from "./setView";
import GetMapInfo from "./getMapInfo";
import { NumberLiteralType } from "typescript";
import styled from "styled-components";

interface Props {
  longitude: number;
  latitude: number;
  zoom: number;
  minZoom: number;
  maxZoom: number;
  maxNativeZoomOSM: number;
  maxNativeZoomSatellite: number;
  bounds: any;
  phases: any;
  project_description: string;
  markerClicked: (poi: any) => void;
  currentPhase?: any;
  showFocuszones: boolean;
  classes?: any;
  host: string;
  mediaStorage: string;
  changeCurrentActiveFocuszoneData: (
    description: string,
    image: string
  ) => void;
  setCurrentActiveFocuszoneQandA: (
    questions: any,
    description?: any,
    image?: any
  ) => void;
  projectName: string;
  isSatteliteButtonVisible: boolean;
  isSearchButtonVisible: boolean;
  isMapBlank: boolean;
  tileLayerBounds: any;
  questionsStyledProps: any;
  translations: any;
}

interface State {
  center: LatLngExpression;
  description: string;
  image: string;
  isOverlay: boolean;
  zoom: number;
  changeView: boolean;
  isSatelliteView: boolean;
  isSearchMarker: boolean;
  projectName: string;
  url: string;
}

interface SearchBarWrapperProps {
  isSateliteButtonVisible: boolean;
  currentPhase: any;
}

const SearchBarWrapper = styled.div<SearchBarWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  top: 12px;
  right: 8px;
`;

const MapModeTogglerWrapper = styled.div<SearchBarWrapperProps>`
  position: absolute;
  left: 85px;
  bottom: 25px;
  @media (max-width: 599px) {
    left: 67px;
  }
`;

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  overlayButton: {
    color: "black",
    backgroundColor: "white",
    marginRight: "8px",
    marginTop: "8px",
    zIndex: 3,
    "&:hover": {
      backgroundColor: "lightgrey",
    },
  },
  searchButton: {
    zIndex: 3,
  },
});

const locationMarkup = renderToStaticMarkup(
  <LocationOnIcon style={{ color: `black` }} className="markerButtons" />
);

const location = divIcon({ html: locationMarkup, iconSize: [20, 20] });

class Map extends Component<Props, State> {
  state = {
    center: L.latLng(this.props.latitude, this.props.longitude),
    description: this.props.project_description,
    image: "",
    showFocuszones: false,
    isOverlay: false,
    zoom: this.props.zoom,
    changeView: false,
    isSatelliteView: true,
    isSearchMarker: false,
    projectName: this.props.projectName,
    url: "",
  };

  chooseIcon = (poi: any) => {
    let chosenIcon;
    switch (poi.Marker) {
      case "url":
        const urlMarkup = renderToStaticMarkup(
          <LinkIcon
            style={{ fill: `rgb(${poi.color})` }}
            className="markerButtons"
          />
        );
        const url = divIcon({ html: urlMarkup, iconSize: [26, 26] });
        chosenIcon = url;
        break;
      case "info":
        const infoMarkup = renderToStaticMarkup(
          <InfoIcon
            style={{ color: `rgb(${poi.color})` }}
            className="markerButtons"
          />
        );
        const info = divIcon({ html: infoMarkup, iconSize: [20, 20] });
        chosenIcon = info;
        break;
      case "question":
        const questionMarkup = renderToStaticMarkup(
          <QuestionIcon
            style={{ color: `rgb(${poi.color})` }}
            className="markerButtons"
          />
        );
        const question = divIcon({ html: questionMarkup, iconSize: [20, 25] });
        chosenIcon = question;
        break;
      case "image":
        const imageMarkup = renderToStaticMarkup(
          <ImageIcon
            style={{ color: `rgb(${poi.color})` }}
            className="markerButtons"
          />
        );
        const image = divIcon({ html: imageMarkup, iconSize: [20, 20] });
        chosenIcon = image;
        break;
      case "image360":
        const panoramaMarkup = renderToStaticMarkup(
          <PanoramicIcon
            style={{ fill: `rgb(${poi.color})` }}
            className="markerButtons"
          />
        );
        const panorama = divIcon({ html: panoramaMarkup, iconSize: [28, 17] });
        chosenIcon = panorama;
        break;
      case "video":
        const videoMarkup = renderToStaticMarkup(
          <VideoIcon
            style={{ color: `rgb(${poi.color})` }}
            className="markerButtons"
          />
        );
        const video = divIcon({ html: videoMarkup, iconSize: [20, 20] });
        chosenIcon = video;
    }

    return chosenIcon;
  };

  changeCenter = (longitude: number, latitude: number) => {
    this.setState({ center: L.latLng(longitude, latitude) });
    this.setState({ zoom: this.props.maxZoom });
    this.setState({ changeView: true });
    this.setState({ isSearchMarker: true });
    setTimeout(() => this.setState({ changeView: false }), 100);
  };

  toggleSatellite = () => {
    this.setState({ isSatelliteView: !this.state.isSatelliteView });
    this.setState({
      url: this.state.isSatelliteView
        ? "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
        : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    });
  };

  render() {
    const { classes } = this.props;
    const bounds = this.props.bounds
      ? L.latLngBounds(
          [this.props.bounds.SWLatitude, this.props.bounds.SWLongitude],
          [this.props.bounds.NELatitude, this.props.bounds.NELongitude]
        )
      : undefined;

    return (
      <div>
        {this.props.isSearchButtonVisible && (
          <SearchBarWrapper
            isSateliteButtonVisible={this.props.isSatteliteButtonVisible}
            currentPhase={this.props.currentPhase}
          >
            <SearchBar
              bounds={this.props.bounds}
              changeCenter={(longitude, latitude) =>
                this.changeCenter(longitude, latitude)
              }
            />
          </SearchBarWrapper>
        )}
        {this.props.isSatteliteButtonVisible && (
          <MapModeTogglerWrapper
            className="appBar-mapMode"
            isSateliteButtonVisible={this.props.isSatteliteButtonVisible}
            currentPhase={this.props.currentPhase}
          >
            <MapModeToggler
              checked={!this.state.isSatelliteView}
              callback={this.toggleSatellite}
              mainAccent={this.props.questionsStyledProps.mainAccent}
              translations={this.props.translations}
            />
          </MapModeTogglerWrapper>
        )}
        <MapContainer
          className="map"
          center={this.state.center}
          zoom={this.state.zoom}
          zoomControl={false}
          minZoom={this.props.minZoom}
          maxZoom={this.props.maxZoom}
          scrollWheelZoom={true}
          maxBounds={bounds}
          maxBoundsViscosity={1.0}
          attribution="TEST"
        >
          <ZoomControl position="bottomright" />
          {!this.props.isMapBlank && (
            <div>
              {this.state.isSatelliteView ? (
                //<BasemapLayer name="Imagery" />
                <TileLayer
                  url="https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                  attribution='Powered by <a target=_blank href="https://www.esri.com">Esri</a> | <a target=�_blank� href="https://tractebel-engie.com/en">By Tractebel ENGIE</a> | <a target=�_blank� href="/termsofuse">Terms of use</a> | <a target=�_blank� href="/privacypolicy">Privacy policy</a>'
                  maxNativeZoom={this.props.maxNativeZoomSatellite}
                  maxZoom={this.props.maxZoom}
                />
              ) : (
                <TileLayer
                  key={this.state.url}
                  attribution='&copy; <a target=_blank href="http://osm.org/copyright">OpenStreetMap</a> contributors | <a target=�_blank� href="https://tractebel-engie.com/en">By Tractebel ENGIE</a> | <a target=�_blank� href="/termsofuse">Terms of use</a> | <a target=�_blank� href="/privacypolicy">Privacy policy</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  maxNativeZoom={this.props.maxNativeZoomOSM}
                  maxZoom={this.props.maxZoom}
                />
              )}
            </div>
          )}
          {this.props.isMapBlank && (
            <TileLayer
              url={""}
              attribution='<a target=�_blank� href="https://tractebel-engie.com/en">By Tractebel ENGIE</a> | <a target=�_blank� href="/termsofuse">Terms of use</a> | <a target=�_blank� href="/privacypolicy">Privacy policy</a>'
            />
          )}
          {this.props.tileLayerBounds !== undefined && (
            <TileLayer
              key={
                this.props.currentPhase.phase_name + this.props.tileLayerBounds
              }
              url={`${this.props.mediaStorage}/tiles/${this.state.projectName}/${this.props.currentPhase.phase_name}/{z}/{x}/{y}.png`}
              tms={true}
              opacity={1}
              attribution=""
              minNativeZoom={this.props.minZoom}
              maxNativeZoom={this.props.maxZoom}
              maxZoom={this.props.maxZoom}
              zIndex={2000}
              bounds={this.props.tileLayerBounds}
            />
          )}
          {this.props.currentPhase &&
            this.props.currentPhase.POIS.map((poi: any, index: number) => (
              <Marker
                key={index}
                position={[poi.latitude, poi.longitude]}
                icon={this.chooseIcon(poi)}
                eventHandlers={{
                  click: (e) => {
                    this.props.markerClicked(poi);
                  },
                  mouseover: (e) => {
                    e.target.openPopup();
                  },
                  mouseout: (e) => {
                    e.target.closePopup();
                  },
                }}
              >
                <Popup closeButton={false}>{poi.description_short}</Popup>
              </Marker>
            ))}
          {this.state.isSearchMarker && (
            <Marker position={this.state.center} icon={location}></Marker>
          )}
          {/* Drawing focuszones */}
          {this.props.currentPhase &&
            this.props.currentPhase.FocusZones &&
            this.props.showFocuszones &&
            this.props.currentPhase.FocusZones.map(
              (data: any, index: number) => {
                let polygonVertices: L.LatLng[] = [];
                data.Polygon.coordinates.map(
                  (vertex: any, vertexIndex: number) => {
                    polygonVertices.push(latLng(vertex[1], vertex[0]));
                  }
                );
                return (
                  <Polygon
                    key={index}
                    className="focuszone"
                    positions={polygonVertices}
                    pathOptions={{ color: data.Polygon.RGBA }}
                    eventHandlers={{
                      click: () => {
                        const Image = data.Image
                          ? data.Image.formats.small
                            ? data.Image.formats.small.url
                            : data.Image.formats.thumbnail.url
                          : "";
                        this.props.setCurrentActiveFocuszoneQandA(
                          data.id,
                          data.Description,
                          Image
                        );
                      },
                    }}
                  />
                );
              }
            )}
          {this.state.changeView && (
            <SetView center={this.state.center} zoom={this.state.zoom} />
          )}
          <GetMapInfo />
        </MapContainer>
      </div>
    );
  }
}

export default withStyles(useStyles)(Map);
