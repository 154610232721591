import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "./App.css";
import Home from "./components/home";
import Privacy from "./components/privacy";
import TermsOfUse from "./components/termsOfUse";
import { isDev, mediaHost } from "./environment";

const host = isDev();
const mediaStorage = mediaHost();

interface State {
  sites: any[];
  firstVisit: boolean;
  url: any;
  redirect: boolean;
  isIE: boolean;
}

declare global {
  interface Document {
    documentMode?: any;
  }
}

class App extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    const obj = {
      menu: params.get("menu") || "",
      focusZoneMenu: params.get("fzmenu") || "",
      focusZoneMenuId: params.get("fzmenuid") || "",
      showFocusZones: params.get("fz") || "",
      phase: params.get("phase") || "",
      qanda: params.get("qanda") || "",
      currentZone: params.get("cfzone") || "",
      zoom: params.get("zoom") || "",
      lat: params.get("lat") || "",
      lng: params.get("lng") || "",
      info: params.get("info") || "",
      question: params.get("question") || "",
      image: params.get("image") || "",
      imagePhase: params.get("imagep") || "",
      pano: params.get("pano") || "",
      video: params.get("video") || "",
    };

    this.state = {
      sites: [],
      firstVisit: localStorage.noFirstVisit ? false : true,
      url: obj,
      redirect: false,
      isIE: false,
    };
  }

  async componentDidMount() {
    this.checkBrowser();
    const response = await fetch(host + "/sites", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const body = await response.json();
    this.setState({ sites: body });
    this.checkSite();
  }

  checkBrowser = () => {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    this.setState({ isIE: isIE });
  };

  checkSite = () => {
    const tempURL = window.location.href
      .slice(
        window.location.href.lastIndexOf("/") + 1,
        window.location.href.includes("?")
          ? window.location.href.lastIndexOf("?")
          : window.location.href.length
      )
      .toLowerCase();
    const termsofuse = "termsofuse";
    const privacypolicy = "privacypolicy";
    let found = false;
    this.state.sites.forEach((site: any) => {
      let name = encodeURIComponent(site.name);
      if (
        tempURL === name.toLowerCase() ||
        tempURL === termsofuse.toLowerCase() ||
        tempURL === privacypolicy.toLowerCase()
      ) {
        found = true;
        return null;
      }
    });
    if (found === false) {
      this.setState({ redirect: true });
    }
  };

  render() {
    if (!localStorage.noFirstVisit) {
      localStorage.noFirstVisit = true;
    }

    return (
      <React.Fragment>
        {this.state.sites !== null && !this.state.isIE && (
          <Router>
            <div className="App">
              {this.state.sites.map((site: any, index: number) => (
                <Route
                  key={index}
                  path={"/" + site.name}
                  render={({ location, history }) => {
                    return (
                      <Home
                        site={site}
                        firstVisit={this.state.firstVisit}
                        host={host}
                        mediaStorage={mediaStorage}
                        url={this.state.url}
                        history={history}
                      />
                    );
                  }}
                ></Route>
              ))}
            </div>
            <Route
              path={"/termsofuse"}
              render={({ location, history }) => {
                return <TermsOfUse />;
              }}
            ></Route>
            <Route
              path={"/privacypolicy"}
              render={({ location, history }) => {
                return <Privacy />;
              }}
            ></Route>
            {this.state.redirect && <Redirect to="/UrbanWebMap" />}
          </Router>
        )}
        {this.state.isIE && (
          <div>
            <h1>
              Internet Explorer is not supported, please use other browser
            </h1>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default App;
