import React, { Component } from 'react';
import Sidebar from './sidebar';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Styles } from '@material-ui/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Pannellum } from 'pannellum-react';
import UrlService from '../services/urlService';
import { useState } from 'react';
import { useEffect } from 'react';

interface Props {
  url: string;
  host: string;
  deleteContent: () => void;
  classes?: any;
  phases: any;
  poi: any;
  currentPhase: any;
  hasPhases: boolean;
  history: any;
  translations: any;
  projectName: string;
  sidebarWidth: number;
  phaseSelected: (phaseId: number) => void;
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  closeDrawer: () => void;
  googleCookie: boolean;
  questionsStyledProps: any;
}

interface State {
  poi: any;
  url: string;
  poiOtherPhases: any;
  currentPhase: any;
  phases: any;
  isDrawerOpen: boolean;
  description: string;
}

const useIconStyle = makeStyles({
  root: (props: any) => ({
    '&.MuiIconButton-colorPrimary': {
      backgroundColor: props.lighterAccent ? props.lighterAccent : 'lightgrey',
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: props.mainAccent ? props.mainAccent : 'grey',
    },
    width: '48px',
    top: '50px',
  }),
  phaseButtons: {
    marginLeft: '8px',
    top: '150px',
    zIndex: 1300,
  },
});

const useButtonStyle = makeStyles({
  root: {
    color: 'black',
  },
});

const urlService = UrlService.getInstance();

function Panorama(props: Props, state: State) {
  const iconClass = useIconStyle(props.questionsStyledProps);
  const buttonClass = useButtonStyle();

  const [url, setUrl] = useState(props.host + '/' + props.url.split('/').pop());
  const [poi, setPoi] = useState(props.poi);
  const [poiOtherPhases, setPoiOtherPhases] = useState(
    props.poi.other_phases ? props.poi.other_phases.split(',') : ''
  );
  const [currentPhase, setCurrentPhase] = useState(props.currentPhase);
  const [phases, setPhases] = useState(props.currentPhase);
  const [isDrawerOpen, setIsDrawerOpen] = useState(props.isDrawerOpen);
  const [description, setDescription] = useState('');

  useEffect(() => {
    for (let phase of props.phases) {
      for (let point of phase.POIS) {
        if (poiOtherPhases) {
          for (let otherPhase of poiOtherPhases) {
            if (point.poi_id === otherPhase) {
              let phaseArray = [];
              phaseArray.push(phases);
              phaseArray.push(phase);
              setPhases(phaseArray);
            }
          }
        }
      }
    }
      setDescription(poi.description);
    const pnlm = document.querySelector('.pnlm-container') as HTMLElement;
    pnlm.style.backgroundColor = props.questionsStyledProps.mainAccent;
  }, []);

  const onPhaseSelected = (phaseId: number) => {
    const otherPhasesArray = poi.other_phases.split(',');
    for (let phase of props.phases) {
      if (phaseId === phase.id) {
        for (let point of phase.POIS) {
          for (let otherPhase of otherPhasesArray) {
            if (point.poi_id === otherPhase) {
              setPoi(point);
              setCurrentPhase(phase);
              setUrl(props.host + '/' + point.img[0].url.split('/').pop());
              props.history.push(
                urlService.setPanoramaParam(point.id.toString())
              );
              props.history.push(
                urlService.setImagePhaseParam(phase.id.toString())
              );
              setDescription(point.description);
            }
          }
        }
      }
    }
    props.phaseSelected(phaseId);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    props.toggleDrawer();
  };

  const closeDrawer = () => {
    if (isDrawerOpen === true) {
      setIsDrawerOpen(false);
      props.closeDrawer();
    }
  };

  const showFocusZones = () => {
    return false;
  };

  return (
    <React.Fragment>
      <Sidebar
        isDrawerOpen={isDrawerOpen}
        isQuestion={false}
        closeDrawer={closeDrawer}
        toggleDrawer={toggleDrawer}
        description={description}
        image={''}
        phases={phases}
        phaseSelected={onPhaseSelected}
        showFocuszones={false}
        showFocuszonesCallback={showFocusZones}
        currentPhase={currentPhase}
        hasPhases={props.hasPhases}
        isMediaShown={true}
        translations={props.translations}
        projectName={props.projectName}
        sidebarWidth={props.sidebarWidth}
        googleCookie={props.googleCookie}
        questionsStyledProps={props.questionsStyledProps}
      />
      <div className="panorama">
        <IconButton
          className={iconClass.root}
          color="primary"
          onClick={props.deleteContent}
        >
          <CloseIcon className={buttonClass.root} />
        </IconButton>
        <Pannellum
          width="100%"
          height="100vh"
          image={url + '?x-request=html'}
          pitch={10}
          yaw={180}
          hfov={110}
          autoLoad
          showZoomCtrl={false}
        />
      </div>
    </React.Fragment>
  );
}

export default Panorama;
