export function isDev(): string {
  if (!process.env.REACT_APP_CMS_HOST) {
    return "";
  }

  return process.env.REACT_APP_CMS_HOST;
}

export function mediaHost(): string {
  if (!process.env.REACT_APP_MEDIA_HOST) {
    return "";
  }

  return process.env.REACT_APP_MEDIA_HOST;
}

export function emailHost(): string {
  if (!process.env.REACT_APP_EMAIL_HOST) {
    return "";
  }

  return process.env.REACT_APP_EMAIL_HOST;
}
