import React, { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import SingleQuestion from "./singleQuestion";
import DOMPurify from "dompurify";
import EmailForm from "./emailform";
import Drawer from "@material-ui/core/Drawer";
import { Theme, withStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import { Omit } from "@material-ui/types";

import { Button, IconButton, Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { WithStyles } from "@material-ui/core/styles";
import { ButtonProps } from "@material-ui/core/Button";

import CookieIcon from "../icons/cookie.svg";

import "./questions.css";
import { makeStyles } from "@material-ui/styles";

interface Props {
  closeQandA: () => void;
  phases: any[];
  currentFocuszoneContentQandA: any;
  description: string;
  image: string;
  questionsStyledProps: QuestionsStyledProps;
  project_name: string;
  classes?: any;
  sidebarWidth: any;
  googleCookie: boolean;
  translations: any;
  openCookie?: () => void;
}

export interface QuestionsStyledProps {
  mainAccent?: string;
  lighterAccent?: string;
  mainBackground?: string;
  lighterBackground?: string;
  mainAccentFontColor?: string;
  lighterAccentFontColor?: string;
  mainBackgroundFontColor?: string;
  sidebarWidth?: any;
}

interface State {
  showEmailComponent: boolean;
  searchKeyword: string;
}

const useStyles = makeStyles({
  root: {
    height: "100%",
  },
});
const MainWrapper = styled.div`
  z-index: 2000;
  position: absolute;
  overflow-y: hidden;
  width: calc(100vw - 17px);
  background-color: white;
  display: flex;
  width: 100%;
  min-height: 100%;
`;
const LeftSideInnerWrapper = styled.div<QuestionsStyledProps>`
  z-index: 2;
  width: ${(props) => props.sidebarWidth}px;
`;

const PrivacyPolicyNote = styled.div`
  width: 100%;
  text-align: center;
`;
const SidebarWrapper = styled.div`
  min-height: calc(100vh - 20px);
  height: calc(100% - 20px);
`;
const ContentWidthWrapper = styled.div`
  width: 80%;
  margin: auto;
  height: 100%;
`;
const RightSideInnerWrapper = styled.div<QuestionsStyledProps>`
  width: 100%;
  background-color: ${(props) => props.mainBackground};
`;
const ContentMarginReduce = styled.div<QuestionsStyledProps>`
  margin-top: 0px;
  overflow: hidden;
`;
const TopBar = styled.div<QuestionsStyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1%;
  margin: 0 160px 0 160px;
`;
const TopTitle = styled.h1<QuestionsStyledProps>`
  color: ${(props) => props.mainAccent};
`;

const TemporaryItem = styled.div`
  background-color: white;
  outline: 1px solid black;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
`;

const SearchBarWrapper = styled.div`
  margin-bottom: 1rem;
`;
const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 160px 100px 160px;
`;

const useContainerStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export default function Questions(props: Props, state: State) {
  const [showEmailComponent, setShowEmailComponent] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const containerClass = useContainerStyle();

  const rootClass = useStyles();

  return (
    <MainWrapper>
      <div
        style={{
          zIndex: 2000,
          width: "10px",
          height: "100%",
          position: "absolute",
          backgroundColor: props.questionsStyledProps.mainAccent,
          color: props.questionsStyledProps.mainAccentFontColor,
        }}
      ></div>
      <LeftSideInnerWrapper
        lighterBackground={props.questionsStyledProps.lighterBackground}
        sidebarWidth={props.sidebarWidth}
      >
        <Paper
          elevation={3}
          className={rootClass.root}
          style={{
            width: props.sidebarWidth,
            backgroundColor: props.questionsStyledProps.mainBackground,
            color: props.questionsStyledProps.mainBackgroundFontColor,
          }}
        >
          {showEmailComponent ? (
            <div>
              {props.googleCookie ? (
                <SidebarWrapper>
                  <ContentWidthWrapper>
                    <EmailForm
                      project_name={props.project_name}
                      questionsStyledProps={props.questionsStyledProps}
                      sidebarWidth={props.sidebarWidth}
                      translations={props.translations}
                      currentFocusZone={props.currentFocuszoneContentQandA}
                      backButtonCallback={() => {
                        setShowEmailComponent(!showEmailComponent);
                      }}
                    />
                  </ContentWidthWrapper>
                </SidebarWrapper>
              ) : (
                <div className={containerClass.root}>
                  <h3>{props.translations.Cookie_rejected_info}</h3>
                  <Button onClick={props.openCookie}>
                    <img src={CookieIcon} />
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <SidebarWrapper>
              <ContentMarginReduce>
                <ContentWidthWrapper>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(props.description,
                        { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }),
                    }}
                  />
                </ContentWidthWrapper>
              </ContentMarginReduce>
            </SidebarWrapper>
          )}
        </Paper>
      </LeftSideInnerWrapper>
      <RightSideInnerWrapper
        mainBackground={props.questionsStyledProps.lighterBackground}
      >
        <TopBar className="qa-topBar">
          <TopTitle mainAccent={props.questionsStyledProps.mainAccent}>
            {props.translations.QA_title}
          </TopTitle>

          <div>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: props.questionsStyledProps.mainAccent,
                color: props.questionsStyledProps.mainAccentFontColor,
              }}
              onClick={() => setShowEmailComponent(!showEmailComponent)}
            >
              <ChatBubbleOutlineIcon style={{ marginRight: "8px" }} />
              <span> {props.translations.Ask_question_button}</span>
            </Button>
          </div>
          <IconButton
            className="qa-closeButton"
            onClick={() => props.closeQandA()}
            style={{ position: "absolute", right: "2%" }}
          >
            <ClearIcon />
          </IconButton>
        </TopBar>

        <QuestionsContainer className="qa-questionsContainer">
          <SearchBarWrapper>
            <TextField
              id="outlined-basic"
              label={props.translations.QA_search}
              variant="filled"
              onChange={(event) => {
                setSearchKeyword(event.target.value.toLowerCase());
              }}
            />
          </SearchBarWrapper>
          {props.phases.map((phase: any, index: number) => {
            let focuszone = phase.FocusZones.find(
              (zone: any) => zone.id === props.currentFocuszoneContentQandA
            );
            if (focuszone !== undefined) {
              return focuszone.questions.map((questionWrapper: any) => {
                return questionWrapper.map((innerObj: any) => {
                  return innerObj.QuestionBuckets.Questions.map(
                    (question: any, index: number) => {
                      if (searchKeyword !== "") {
                        if (
                          question.Question.toLowerCase().includes(
                            searchKeyword.trim()
                          ) ||
                          question.Answer.toLowerCase().includes(
                            searchKeyword.trim()
                          )
                        ) {
                          return (
                            <SingleQuestion
                              title={question.Question}
                              body={question.Answer}
                              key={index}
                              lighterAccent={
                                props.questionsStyledProps.lighterAccent
                              }
                              lighterAccentFontColor={
                                props.questionsStyledProps
                                  .lighterAccentFontColor
                              }
                              mainAccentFontColor={
                                props.questionsStyledProps.mainAccentFontColor
                              }
                              mainBackground={
                                props.questionsStyledProps.mainBackground
                              }
                              mainBackgroundFontColor={
                                props.questionsStyledProps
                                  .mainBackgroundFontColor
                              }
                            />
                          );
                        }
                      } else {
                        return (
                          <SingleQuestion
                            title={question.Question}
                            body={question.Answer}
                            lighterAccent={
                              props.questionsStyledProps.lighterAccent
                            }
                            lighterAccentFontColor={
                              props.questionsStyledProps.lighterAccentFontColor
                            }
                            key={index}
                            mainAccentFontColor={
                              props.questionsStyledProps.mainAccentFontColor
                            }
                            mainBackground={
                              props.questionsStyledProps.mainBackground
                            }
                            mainBackgroundFontColor={
                              props.questionsStyledProps.mainBackgroundFontColor
                            }
                          />
                        );
                      }
                    }
                  );
                });
              });
            } else {
              return null;
            }
          })}
        </QuestionsContainer>
      </RightSideInnerWrapper>
    </MainWrapper>
  );
}
