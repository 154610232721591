export default class UrlService {
  private static instance: UrlService;

  static getInstance(): UrlService {
    if (!UrlService.instance) {
      UrlService.instance = new UrlService();
    }
    return UrlService.instance;
  }

  setMenuParam(menu: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("menu", menu);
    return pathname + "?" + params.toString();
  }

  setPhaseParam(phase: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("phase", phase);
    return pathname + "?" + params.toString();
  }

  setQandaParam(qanda: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("qanda", qanda);
    return pathname + "?" + params.toString();
  }

  removeQandaParam() {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("qanda");
    return pathname + "?" + params.toString();
  }

  setCfzoneParam(cfzone: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("cfzone", cfzone);
    return pathname + "?" + params.toString();
  }

  setInfoParam(info: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("info", info);
    return pathname + "?" + params.toString();
  }

  removeInfoParam() {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("info");
    return pathname + "?" + params.toString();
  }
  setQuestionParam(question: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("question", question);
    return pathname + "?" + params.toString();
  }

  removeQuestionParam() {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("question");
    return pathname + "?" + params.toString();
  }

  setImageParam(image: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("image", image);
    return pathname + "?" + params.toString();
  }

  removeImageParam() {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("image");
    return pathname + "?" + params.toString();
  }
  setImagePhaseParam(imagePhase: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("imagep", imagePhase);
    return pathname + "?" + params.toString();
  }

  removeImagePhaseParam() {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("imagep");
    return pathname + "?" + params.toString();
  }

  setPanoramaParam(panorama: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("pano", panorama);
    return pathname + "?" + params.toString();
  }

  removePanoramaParam() {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("pano");
    return pathname + "?" + params.toString();
  }

  setVideoParam(video: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("video", video);
    return pathname + "?" + params.toString();
  }

  removeVideoParam() {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.delete("video");
    return pathname + "?" + params.toString();
  }

  setShowFocusZoneParam(show: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("fz", show);
    return pathname + "?" + params.toString();
  }

  setFocuszoneMenu(show: string, question: string) {
    let pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    params.set("fzmenu", show);
    params.set("fzmenuid", question);
    return pathname + "?" + params.toString();
  }
}
