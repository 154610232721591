import { Component } from "react";
import "./sidebar.css";
import EmailForm from "./emailform";
import PhaseSelector from "./phaseSelector";
import Button from "@material-ui/core/Button";
import CookieIcon from "../icons/cookie.svg";
import { Styles } from "@material-ui/styles";
import { makeStyles, Theme, withStyles } from "@material-ui/core";
import clsx from "clsx";
import FocuszoneSelector from "./focuszoneSelector";
import { FocuszoneToggler, MapModeToggler } from "./togglers";
import { ChangeEvent } from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import DOMPurify from "dompurify";
import styled from "styled-components";
import MiniDrawer from "./customDrawer";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

interface Props {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  image: string;
  description: string;
  isQuestion: boolean;
  toggleDrawer: () => void;
  classes?: any;
  openTour?: () => void;
  phases: any[];
  phaseSelected: (phaseId: number) => void;
  showFocuszones: boolean;
  showFocuszonesCallback: (event: ChangeEvent<HTMLInputElement>) => void;
  currentPhase: any;
  hasPhases: boolean;
  isMediaShown?: boolean;
  translations: any;
  projectName: string;
  showQandAButton?: boolean;
  handleQandAButton?: () => void;
  sidebarWidth: number;
  isMarkerClicked?: boolean;
  goBack?: () => void;
  questionsStyledProps: any;
  currentQuestionPOI?: any;
  googleCookie: boolean;
  openCookie?: () => void;
}

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const PrivacyPolicyNote = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: small;
  color: rgba(0, 0, 0, 0.5);
`;
const HelpButtonWrapper = styled.div`
  margin: auto;
  width: 100%;
`;

const useSidebarStyle = makeStyles((theme) => ({
  root: (props: any) => ({
    position: "absolute",
    backgroundColor: props.mainBackground,
    zIndex: 1201,
  }),

  sideMenu: {
    maxWidth: "100px",
  },
  helpButton: {
    width: "100%",
  },
  appBar: {
    position: "fixed",
    marginLeft: "85px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: 195,
    }),
    zIndex: 10,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "64px",
      maxWidth: "160px",
    },
  },
  appBarShift: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: 225,
    }),
  },
  mediaShown: {
    zIndex: 1201,
  },
}));

const useContainerStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

function Sidebar(props: Props) {
  const sidebarClass = useSidebarStyle(props.questionsStyledProps);

  const containerClass = useContainerStyle();

  const [buttonMargin, setButtonMargin] = useState(
    props.sidebarWidth ? props.sidebarWidth + 8 : 266
  );

  useEffect(() => {
    setButtonMargin(props.sidebarWidth ? props.sidebarWidth + 8 : 266);
    if (props.isDrawerOpen) drawerBehaviour();
  }, []);

  useEffect(() => {
    drawerBehaviour();
  });

  const drawerBehaviour = () => {
    let pannellumControls = document.querySelector(".pnlm-controls-container");
    if (props.isDrawerOpen === true) {
      pannellumControls?.classList.add(
        "pnlm-controls-container-animation-open"
      );
      pannellumControls?.setAttribute(
        "style",
        `margin-left: ${buttonMargin}px !important`
      );
    } else if (props.isDrawerOpen === false) {
      pannellumControls?.classList.remove(
        "pnlm-controls-container-animation-open"
      );
      pannellumControls?.removeAttribute("style");
    }

    const appBar = document.querySelector<HTMLElement>(".appBar");

    const appBarMap = document.querySelector<HTMLElement>(".appBar-mapMode");

    if (props.isDrawerOpen) {
      appBar?.setAttribute(
        "style",
        `margin-left: ${buttonMargin}px !important`
      );
      appBarMap?.setAttribute(
        "style",
        `margin-left: ${buttonMargin - 84}px !important`
      );
    } else {
      appBar?.removeAttribute("style");
      appBarMap?.removeAttribute("style");
      document.querySelector<HTMLElement>(".appBar")?.removeAttribute("style");
    }
  };

  return (
    <div className="sidebar-div">
      <MiniDrawer
        drawerWidth={props.sidebarWidth}
        isDrawerOpen={props.isDrawerOpen}
        toggleDrawer={props.toggleDrawer}
        closeDrawer={props.closeDrawer}
        goBack={props.goBack}
        openTour={props.openTour}
        openCookie={props.openCookie}
        questionsStyledProps={props.questionsStyledProps}
      >
        {props.showQandAButton && (
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: props.questionsStyledProps.mainAccent,
                color: props.questionsStyledProps.mainAccentFontColor,
              }}
              onClick={props.handleQandAButton}
            >
              {props.translations.Open_QA_button}
            </Button>
          </div>
        )}
        {props.isQuestion && (
          <div>
            {props.googleCookie ? (
              <EmailForm
                topLabel={props.description}
                project_name={props.projectName}
                questionsStyledProps={props.questionsStyledProps}
                currentQuestionPOI={props.currentQuestionPOI}
                sidebarWidth={props.sidebarWidth}
                translations={props.translations}
              />
            ) : (
              <div className={containerClass.root}>
                <h3>{props.translations.Cookie_rejected_info}</h3>
                <Button onClick={props.openCookie}>
                  <img src={CookieIcon} />
                </Button>
              </div>
            )}
          </div>
        )}
        {!props.isQuestion && (
          <div
            className="description-text"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.description,
                { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }),
            }}
          />
        )}
      </MiniDrawer>
      <div className="appBar appBar-animation">
        {props.currentPhase && props.phases.length > 1 && (
          <div className="appBar-phaseSelector">
            <PhaseSelector
              currentPhase={props.currentPhase}
              questionsStyledProps={props.questionsStyledProps}
              phases={props.phases}
              phaseSelected={(phaseId: any) => props.phaseSelected(phaseId)}
              className={[
                clsx({
                  [sidebarClass.appBarShift]: props.isDrawerOpen,
                  [sidebarClass.mediaShown]: props.isMediaShown,
                }),
              ].join(" ")}
            />
          </div>
        )}
        {props.currentPhase && props.currentPhase.FocusZones.length > 0 && (
          <React.Fragment>
            <FocuszoneToggler
              checked={props.showFocuszones}
              callback={props.showFocuszonesCallback}
              mainAccent={props.questionsStyledProps.mainAccent}
              translations={props.translations}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
