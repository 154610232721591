import React from "react";
import styled from "styled-components";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { makeStyles, withStyles } from "@material-ui/core";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const useStylesSummary = makeStyles({
  root: (props: any) => ({
    "&.MuiAccordionSummary-root": {
      backgroundColor: props.lighterAccent,
      color: props.lighterAccentFontColor,
    },
  }),
});

const useStylesDetails = makeStyles({
  root: (props: any) => ({
    "&.MuiAccordionDetails-root": {
      backgroundColor: props.mainBackground,
      color: props.mainBackgroundFontColor,
    },
  }),
});

interface Props {
  title: string;
  body: string;
  mainAccentFontColor: any;
  mainBackground: any;
  lighterAccent: any;
  lighterAccentFontColor: any;
  mainBackgroundFontColor: any;
}

const SingleQuestion = (props: Props) => {
  const classesSummary = useStylesSummary(props);
  const classesDetails = useStylesDetails(props);

  const [expandIconStyle, setExpandIconStyle] = useState({
    color: props.lighterAccentFontColor,
  });

  return (
    <Wrapper>
      <Accordion className="questions-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={expandIconStyle} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: classesSummary.root }}
        >
          <strong>{props.title}</strong>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classesDetails.root }}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.body,
                { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }),
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Wrapper>
  );
};

export default SingleQuestion;
