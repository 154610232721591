import React, { Component, useState } from 'react';

import Sidebar from './sidebar';

import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Styles } from '@material-ui/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import UrlService from '../services/urlService';
import { useEffect } from 'react';
import CookieIcon from '../icons/cookie.svg';

interface Props {
  deleteContent: () => void;
  url?: string;
  classes?: any;
  phases: any;
  poi: any;
  currentPhase: any;
  hasPhases: boolean;
  history: any;
  translations: any;
  projectName: string;
  sidebarWidth: number;
  showFocuszones: boolean;
  phaseSelected: (phaseId: number) => void;
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  closeDrawer: () => void;
  googleCookie: boolean;
  youtubeCookie: boolean;
  questionsStyledProps: any;
  openCookie: () => void;
}

interface State {
  phases: any;
  poi: any;
  currentPhase: any;
  url?: string;
  isDrawerOpen: boolean;
  description: string;
}

const useIconStyle = makeStyles({
  root: (props: any) => ({
    '&.MuiIconButton-colorPrimary': {
      backgroundColor: props.lighterAccent ? props.lighterAccent : 'lightgrey',
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: props.mainAccent ? props.mainAccent : 'grey',
    },
    width: '48px',
    top: '50px',
  }),
  phaseButtons: {
    marginLeft: '8px',
    top: '150px',
    zIndex: 1300,
  },
});

const useButtonStyle = makeStyles({
  root: {
    color: 'black',
  },
});

const useContainerStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

const urlService = UrlService.getInstance();

function Video(props: Props, state: State) {
  const iconClass = useIconStyle(props.questionsStyledProps);
  const buttonClass = useButtonStyle();
  const containerClass = useContainerStyle();

  const [phases, setPhases] = useState(props.currentPhase);
  const [poi, setPoi] = useState(props.poi);
  const [currentPhase, setCurrentPhase] = useState(props.currentPhase);
  const [url, setUrl] = useState('https://www.youtube.com/embed/' + props.url);
  const [isDrawerOpen, setIsDrawerOpen] = useState(props.isDrawerOpen);
  const [description, setDescription] = useState('');

  useEffect(() => {
    for (let phase of props.phases) {
      for (let point of phase.POIS) {
        if (poi.other_phases) {
          const otherPhasesArray = poi.other_phases.split(',');
          for (let otherPhase of otherPhasesArray) {
            if (point.poi_id === otherPhase) {
              let phaseArray = [];
              phaseArray.push(phases);
              phaseArray.push(phase);
              setPhases(phaseArray);
            }
          }
        }
      }
    }
    setDescription(poi.description);
  }, []);

  const onPhaseSelected = (phaseId: number) => {
    const otherPhasesArray = poi.other_phases.split(',');
    for (let phase of props.phases) {
      if (phaseId === phase.id) {
        for (let point of phase.POIS) {
          for (let otherPhase of otherPhasesArray) {
            if (point.poi_id === otherPhase) {
              setPoi(point);
              setCurrentPhase(phase);
              setUrl(point.url);

              props.history.push(
                urlService.setPanoramaParam(point.id.toString())
              );
              props.history.push(
                urlService.setImagePhaseParam(phase.id.toString())
              );
              setDescription(point.description);
            }
          }
        }
      }
    }
    props.phaseSelected(phaseId);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    props.toggleDrawer();
  };

  const closeDrawer = () => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
      props.closeDrawer();
    }
  };

  const showFocusZones = () => {
    return false;
  };

  return (
    <React.Fragment>
      <Sidebar
        isDrawerOpen={isDrawerOpen}
        isQuestion={false}
        closeDrawer={closeDrawer}
        toggleDrawer={toggleDrawer}
        description={description}
        image={''}
        phases={phases}
        phaseSelected={onPhaseSelected}
        showFocuszones={props.showFocuszones}
        showFocuszonesCallback={showFocusZones}
        currentPhase={currentPhase}
        hasPhases={props.hasPhases}
        isMediaShown={true}
        translations={props.translations}
        projectName={props.projectName}
        sidebarWidth={props.sidebarWidth}
        googleCookie={props.googleCookie}
        questionsStyledProps={props.questionsStyledProps}
      />
      <div className="video-container" onClick={props.deleteContent}>
        <IconButton
          className={iconClass.root}
          color="primary"
          onClick={props.deleteContent}
        >
          <CloseIcon className={buttonClass.root} />
        </IconButton>
        <div className="video-box">
          {props.youtubeCookie ? (
            <iframe
              className="video"
              width="853"
              height="480"
              src={url}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          ) : (
            <div className={containerClass.root}>
              <h3>{props.translations.Cookie_rejected_info}</h3>
              <Button onClick={props.openCookie}>
                <img src={CookieIcon} />
              </Button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Video;
