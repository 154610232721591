import { Component } from 'react';
import { Button, makeStyles, Theme, withStyles } from '@material-ui/core';
import { Styles } from '@material-ui/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import clsx from 'clsx';

interface Props {
  phases: any[];
  classes?: any;
  phaseSelected: (phaseId: number) => void;
  className?: any;
  currentPhase: any;
  questionsStyledProps: any;
}

const useStylesButton = makeStyles({
  root: {
    opacity: 1,
    zIndex: 1202,
    backgroundColor: 'white !important',
    position: 'relative',
    top: '12px !important',
    color: 'black',
    marginBottom: '4px',
    minWidth: '90px',
    textTransform: 'none',
    height: '40px',
    justifyContent: 'flex-start',
    '&:hover': {
      opacity: 1,
      backgroundColor: '#d4d4d4 !important',
    },
    '&$selected': {
      opacity: 1,
      backgroundColor: (props: any) =>
        `${props.questionsStyledProps.mainAccent} !important`,
      color: (props: any) =>
        `${props.questionsStyledProps.mainAccentFontColor}`,
    },
  },
  selected: (props: any) => ({
    fontWeight: 'bold',
  }),
});

const sortPhases = (props: any) => {
  let phasesArray = props.phases;
  for (let i = 0; i < phasesArray.length; i++) {
    for (let j = i + 1; j < phasesArray.length; j++) {
      if (phasesArray[i].id > phasesArray[j].id) {
        const temp = phasesArray[i];
        phasesArray.splice(i, 1, phasesArray[j]);
        phasesArray.splice(j, 1, temp);
      }
    }
  }
};

const PhaseSelector = (props: any) => {
  sortPhases(props);
  const classes = useStylesButton(props);

  return (
    <ToggleButtonGroup
      value={props.currentPhase.id}
      exclusive
      orientation="vertical"
      className={clsx(props.className)}
    >
      {props.phases.length > 1 &&
        props.phases.map((phase: any, index: number) => (
          <ToggleButton
            key={index}
            classes={{ root: classes.root, selected: classes.selected }}
            onClick={() => props.phaseSelected(phase.id)}
            value={phase.id}
          >
            {phase.phase_name}
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
};

export default PhaseSelector;
