import React from 'react';
import Card from '@material-ui/core/Card';
import {
  CardContent,
  createStyles,
  makeStyles,
  Switch,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { SwitchProps } from '@material-ui/core/Switch/Switch';
import { SwitchClassKey } from '@material-ui/core/Switch';
import MapIcon from '@material-ui/icons/Map';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import clsx from 'clsx';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  [x: string]: any;
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
  test?: any;
}

interface TogglerProps {
  checked: boolean;
  callback: (any: any) => void;
  mainAccent: string;
  translations: any;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 28,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      transitionDuration: '200ms',
    },
    colorSecondary: (props: any) => ({
      '&$checked': {
        transform: 'translateX(16px)',
        color: 'white',
        '&$checked + $track': {
          backgroundColor: props.mainAccent,
          opacity: 1,
          border: 'none',
          height: 26,
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    }),
    thumb: {
      width: 24,
      height: 24,
    },
    track: (props: any) => ({
      height: 24,
      borderRadius: 24 * 2,
      border: '1px solid #D9DADC',
      opacity: 1,
      backgroundColor: 'white',
      transition: 'background-color 100ms cubic-bezier(0, 0, 0, .1)',
    }),
    checked: (props: any) => ({}),
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        colorSecondary: classes.colorSecondary,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles({
  root: {
    zIndex: 100,
    position: 'relative',
    marginTop: '16px',
    height: '70px',
    width: '130px',
  },
  mapModeToggler: {
    // top: '78px'
  },
  content: {
    padding: '6px !important',
    margin: 'auto',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
  },
});

export const FocuszoneToggler = (props: TogglerProps) => {
  const classes = useStyles();
  return (
    <div className="focuszoneSelector">
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div className={classes.title}>{props.translations.Focuszone_button}</div>
          <div>
            Off
            <IOSSwitch
              mainAccent={props.mainAccent}
              checked={props.checked}
              onChange={props.callback}
            ></IOSSwitch>
            On
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export const MapModeToggler = (props: TogglerProps) => {
  const classes = useStyles();
  return (
    <Card className={[classes.root, classes.mapModeToggler].join(' ')}>
      <CardContent className={classes.content}>
        <div className={classes.title}>{props.translations.Map_mode_button}</div>
        <div className={classes.icons}>
          <FilterHdrIcon></FilterHdrIcon>
          <IOSSwitch
            mainAccent={props.mainAccent}
            checked={props.checked}
            onChange={props.callback}
          ></IOSSwitch>
          <MapIcon></MapIcon>
        </div>
      </CardContent>
    </Card>
  );
};
