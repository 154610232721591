import { QuestionsStyledProps } from "../components/questions";

interface ColourTheme {
  MainAccent: any;
  LighterAccent: any;
  MainBackground: any;
  LighterBackground: any;
}

export default function getColourTheme(
  ColourTheme: ColourTheme
): QuestionsStyledProps {
  let mainAccent;
  let lighterAccent;
  let mainBackground;
  let lighterBackground;
  let mainAccentFontColor;
  let mainBackgroundFontColor;
  let lighterAccentFontColor;

  let TRACTABEL_VIOLET = "#1C2A8B";
  let NAVY = "#245FA5";
  let LIGHT_GRAY = "#F6F6F6";
  let WHITE = "#FFFFFF";

  if (ColourTheme.MainAccent === null) {
    mainAccent = TRACTABEL_VIOLET;
    mainAccentFontColor = getFontColorHex(mainAccent);
  } else if (
    ColourTheme.MainAccent.Red !== "" &&
    ColourTheme.MainAccent.Green !== "" &&
    ColourTheme.MainAccent.Blue !== "" &&
    ColourTheme.MainAccent.Red !== null &&
    ColourTheme.MainAccent.Green !== null &&
    ColourTheme.MainAccent.Blue !== null
  ) {
    mainAccent =
      "rgba(" +
      ColourTheme.MainAccent.Red +
      "," +
      ColourTheme.MainAccent.Green +
      "," +
      ColourTheme.MainAccent.Blue +
      ")";
    mainAccentFontColor = getFontColorRgb(
      ColourTheme.MainAccent.Red,
      ColourTheme.MainAccent.Green,
      ColourTheme.MainAccent.Blue
    );
  } else {
    if (
      ColourTheme.MainAccent.Hex !== null &&
      ColourTheme.MainAccent.Hex !== ""
    ) {
      mainAccent = `${ColourTheme.MainAccent.Hex}`;
      mainAccentFontColor = getFontColorHex(ColourTheme.MainAccent.Hex);
    } else {
      mainAccent = TRACTABEL_VIOLET;
      mainAccentFontColor = getFontColorHex(ColourTheme.MainAccent.Hex);
    }
  }
  if (ColourTheme.LighterAccent === null) {
    lighterAccent = NAVY;
    lighterAccentFontColor = getFontColorHex(lighterAccent);
  } else if (
    ColourTheme.LighterAccent.Red !== "" &&
    ColourTheme.LighterAccent.Green !== "" &&
    ColourTheme.LighterAccent.Blue !== "" &&
    ColourTheme.LighterAccent.Red !== null &&
    ColourTheme.LighterAccent.Green !== null &&
    ColourTheme.LighterAccent.Blue !== null
  ) {
    lighterAccent =
      "rgba(" +
      ColourTheme.LighterAccent.Red +
      "," +
      ColourTheme.LighterAccent.Green +
      "," +
      ColourTheme.LighterAccent.Blue +
      ")";
    lighterAccentFontColor = getFontColorRgb(
      ColourTheme.LighterAccent.Red,
      ColourTheme.LighterAccent.Green,
      ColourTheme.LighterAccent.Blue
    );
  } else {
    if (
      ColourTheme.LighterAccent.Hex !== null &&
      ColourTheme.LighterAccent.Hex !== ""
    ) {
      lighterAccent = `${ColourTheme.LighterAccent.Hex}`;
      lighterAccentFontColor = getFontColorHex(ColourTheme.LighterAccent.Hex);
    } else {
      lighterAccent = NAVY;
      lighterAccentFontColor = getFontColorHex(ColourTheme.LighterAccent.Hex);
    }
  }

  if (ColourTheme.MainBackground === null) {
    mainBackground = LIGHT_GRAY;
    mainBackgroundFontColor = getFontColorHex(mainBackground);
  } else if (
    ColourTheme.MainBackground.Red !== "" &&
    ColourTheme.MainBackground.Green !== "" &&
    ColourTheme.MainBackground.Blue !== "" &&
    ColourTheme.MainBackground.Red !== null &&
    ColourTheme.MainBackground.Green !== null &&
    ColourTheme.MainBackground.Blue !== null
  ) {
    mainBackground =
      "rgba(" +
      ColourTheme.MainBackground.Red +
      "," +
      ColourTheme.MainBackground.Green +
      "," +
      ColourTheme.MainBackground.Blue +
      ")";
    mainBackgroundFontColor = getFontColorRgb(
      ColourTheme.MainAccent.Red,
      ColourTheme.MainAccent.Green,
      ColourTheme.MainAccent.Blue
    );
  } else {
    if (
      ColourTheme.MainBackground.Hex !== null &&
      ColourTheme.MainBackground.Hex !== ""
    ) {
      mainBackground = `${ColourTheme.MainBackground.Hex}`;
      mainBackgroundFontColor = getFontColorHex(ColourTheme.MainBackground.Hex);
    } else {
      mainBackground = LIGHT_GRAY;
      mainBackgroundFontColor = getFontColorHex(ColourTheme.MainBackground.Hex);
    }
  }
  if (ColourTheme.LighterBackground === null) {
    lighterBackground = WHITE;
  } else if (
    ColourTheme.LighterBackground.Red !== "" &&
    ColourTheme.LighterBackground.Green !== "" &&
    ColourTheme.LighterBackground.Blue !== "" &&
    ColourTheme.LighterBackground.Red !== null &&
    ColourTheme.LighterBackground.Green !== null &&
    ColourTheme.LighterBackground.Blue !== null
  ) {
    lighterBackground =
      "rgba(" +
      ColourTheme.LighterBackground.Red +
      "," +
      ColourTheme.LighterBackground.Green +
      "," +
      ColourTheme.LighterBackground.Blue +
      ")";
  } else {
    if (
      ColourTheme.LighterBackground.Hex !== null &&
      ColourTheme.LighterBackground.Hex !== ""
    ) {
      lighterBackground = `${ColourTheme.LighterBackground.Hex}`;
    } else {
      lighterBackground = WHITE;
    }
  }

  return {
    mainAccent: mainAccent,
    lighterAccent: lighterAccent,
    mainBackground: mainBackground,
    lighterBackground: lighterBackground,
    mainAccentFontColor: mainAccentFontColor,
    lighterAccentFontColor: lighterAccentFontColor,
    mainBackgroundFontColor: mainBackgroundFontColor,
  };
}

function getFontColorRgb(r: string, g: string, b: string) {
  let intensity = Math.round(
    (parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000
  );
  if (intensity > 125) {
    return "#000000";
  } else {
    return "#ffffff";
  }
}
function getFontColorHex(hex: string) {
  let rgbValue = hexToRgb(hex);
  let intensity = Math.round(
    (rgbValue.r * 299 + rgbValue.g * 587 + rgbValue.b * 114) / 1000
  );
  if (intensity > 125) {
    return "#000000";
  } else {
    return "#ffffff";
  }
}

function hexToRgb(hex?: string) {
  let r = parseInt(hex!.substring(1, 3), 16),
    g = parseInt(hex!.substring(3, 5), 16),
    b = parseInt(hex!.substring(5, 7), 16);
  return { r: r, g: g, b: b };
}
