export default function Privacy() {
  return (
    <div className="privacypolicy">
      <div>
        <h1>UrbanWebMap</h1>
        <h2>Privacy Statement</h2>
      </div>
      <div className="privacypolicy-legal">
        <p>
          In the context of the “UrbanWebMap” application (hereinafter the
          “Application”), your personal data will be collected in order to allow
          participation in the visualized projects. You will find below all the
          necessary details regarding this processing of your personal data.
        </p>
        <h2>1. Who collects your data?</h2>
        <p>
          Tractebel Engineering SA, whose registered office is located at
          Boulevard Simon Bolivar 34-36, 1000 Brussels, and registered at the
          BCE under number 0412.639.681, collects your data as controller (i.e.
          it itself determines the purposes and means of processing the personal
          data collected).
        </p>
        <h2>2. What data is collected? </h2>
        <p>
          The personal data collected is your name, email and submitted comments
        </p>
        <h2>3. What is the purpose(s) for which this data is collected?</h2>
        <p>
          The processing of personal data is necessary because it allows public
          participation in the application. In order to communicate about the
          answers to questions and remarks, the collection of personal contact
          information is necessary
        </p>
        <h2>4. On what lawful ground is this processing based?</h2>
        <p>
          Regulation (EU) 2016/679 on the protection of individuals with regard
          to the processing of personal data and on the free movement of such
          data ("General Data Protection Regulation" or "GDPR") requires that
          any processing be based on one of the six bases of legitimacy it
          lists: the consent of the data subject, the need for the performance
          of a contract concluded with the data subject or pre-contractual
          measures at the latter's request, a legal obligation, the safeguarding
          of the vital interests of a natural person, a public interest mission
          or the legitimate interest of the controller. In this case, the
          processing is based on Tractebel’s legitimate interest
        </p>
        <h2>5. To whom is the data transferred?</h2>
        <p>The personal data are only transferred to:</p>
        <ul>
          <li>Municipalit(y)(ies) where the project is located</li>
          <li>
            Governmental agencies which own and manage the public infrastructure
            related to the project
          </li>
          <li>
            In the case the project is related to a privately-owned
            construction, the owner’s representatives
          </li>
        </ul>
        <h2>6. How long is the data kept?</h2>
        <p>
          The personal data will only be kept for the time necessary to achieve
          the purpose set out above, i.e. until the on-site realization or the
          formal rejection of the project.
        </p>
        <h2>7. What are your rights?</h2>
        <p>
          As a data subject, you are granted different rights in connection with
          your Personal data. However, Tractebel can take reasonable steps to
          verify your identity before proceeding with your request. Your rights
          are the following:
        </p>
        <ul>
          <li>
            <h4>Right to be informed</h4> Tractebel is transparent by providing
            you any information relating to the processing of your Personal data
            via this privacy statement.
          </li>
          <li>
            <h4>Right to access</h4> You have the right to request access to and
            receive details on the personal data Tractebel holds about you and
            to obtain a copy thereof.
          </li>
          <li>
            <h4>Right to rectification</h4> If the personal data that Tractebel
            holds about you is inaccurate or incomplete, you have the right to
            ask us to update or rectify it.
          </li>
          <li>
            <h4>Right to be forgotten</h4> Under certain circumstances (e.g.
            when the personal data are no longer necessary in relation to the
            purposes for which they were collected), employees have the right to
            request Tractebel to erase their personal data.
          </li>
          <li>
            <h4>Right to restrict processing</h4> In some specific cases, you
            have the right to ask Tractebel to restrict how we process your
            personal data (e.g. when you contest the accuracy of the personal
            data, Tractebel will have to restrict the processing while verifying
            the accuracy of the personal data). This means Tractebel is only
            permitted to store the data but not further process it.
          </li>
          <li>
            <h4>Right to data portability</h4> This right only applies to
            personal data that you have provided to Tractebel and when the
            processing is based on your consent or on the performance of a
            contract when the processing is carried out by automated means.
            Tractebel will provide your personal data in a structured, commonly
            used and machine-readable format to be able to transmit it to
            another controller, or have it directly transmitted by Tractebel if
            technically feasible.
          </li>
          <li>
            <h4>Right to object</h4> Furthermore, you have the right to object
            to the processing of your personal data, on grounds relating to your
            particular situation, if the processing is based on legitimate
            interests of Tractebel or on a public interest. Tractebel will
            discontinue the processing of your personal data, unless we can
            demonstrate compelling legitimate grounds for the processing which
            override your interests or rights, or for the establishment,
            exercise or defence of legal claims.
          </li>
          <li>
            <h4> Right to withdraw consent</h4> Where your consent was required
            for the collection or use of your personal data, you have the right
            to withdraw your consent at any time. However, this withdrawal does
            not affect any processing operations previously carried out on the
            basis of your consent.
          </li>
          <li>
            <h4>Right to lodge a complaint</h4> You have the right to lodge a
            complaint with a supervisory authority (in Belgium:
            “Gegevensbeschermingsautoriteit or Autorité de Protection des
            Données”) if you consider that the processing of your personal data
            infringes this Privacy Statement or the GDPR. For more information,
            please contact your local data protection authority (contact details
            for data protection authorities in the European Economic Area,
            Switzerland and certain non-European countries are available here).
          </li>
        </ul>
        <h2>8. For any additional questions</h2>
        <p>
          For any additional questions or to exercise your rights, you can
          contact privacy@tractebel.engie.com.
        </p>
      </div>
    </div>
  );
}
