import { useMap, useMapEvents } from "react-leaflet"
import { useHistory } from "react-router"

export default function GetMapInfo() {
    const history = useHistory();
    const map = useMapEvents({
        zoomend: () => {
            let pathname = window.location.pathname;
            const params = new URLSearchParams(window.location.search)
            params.set("zoom",map.getZoom().toString());
            history.push(pathname + '?' + params.toString());
        },
        moveend: () => {
            let pathname = window.location.pathname;
            const params = new URLSearchParams(window.location.search)
            params.set("lat", map.getCenter().lat.toString());
            params.set("lng", map.getCenter().lng.toString());
            let pathToBeSet = pathname + '?' + params.toString();
            if(window.location.pathname + window.location.search !== pathToBeSet){
                history.push(pathname + '?' + params.toString());
            }
        }
    })

  
    return null;
}